

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { postApi } from '../../service/apiService';

function SuperAdmin() {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!firstName || !lastName || !email || !password || !phoneNumber) {
            toast('All fields are required');
            return;
        }

        try {
            const response = await postApi('users/signup', { 
                first_name: firstName, 
                middle_name: middlename, 
                last_name: lastName, 
                email, 
                password, 
                phone_number: phoneNumber 
            });
            const { token, error = null } = response.data;

            if (error == null) {
                toast('User Added successfully');
                setFirstName('');
                setMiddlename('');
                setLastName('');
                setEmail('');
                setPassword('');
                setPhoneNumber('');
                navigate('/report/dashboard'); // Redirect to report/dashboard page or appropriate page
            } else {
                setError('An error occurred while adding user');
                toast('An error occurred while adding user');
            }
        } catch (error) {
            console.error('Error occurred while adding user', error);
            setError('An error occurred while adding user');
            toast('An error occurred while adding user');
        }
    };

    return (
        <div>
            <section className="vh-100 " style={{ backgroundColor: "#eee"  , paddingTop:'90px'}}>
                <div className="container h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-lg-12 col-xl-11">
                            <div className="card text-black" style={{ borderRadius: "25px" }}>
                                <div className="card-body p-md-1">
                                    <div className="row justify-content-center">
                                        <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">
                                            <p className="text-center h1 fw-bold mb-4 mx-1 mx-md-4 mt-4">Add new User</p>

                                            <form className="mx-1 mx-md-4" onSubmit={handleSubmit}>
                                                <div className="d-flex flex-row align-items-center mb-4">
                                                    <i className="fas fa-user fa-lg me-3 fa-fw" style={{ marginBottom: '30px' }}></i>
                                                    <div className="form-outline flex-fill mb-0">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            value={firstName} 
                                                            onChange={(e) => setFirstName(e.target.value)} 
                                                            required 
                                                        />
                                                        <label className="form-label">First Name</label>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row align-items-center mb-4">
                                                    <i className="fas fa-user fa-lg me-3 fa-fw" style={{ marginBottom: '30px' }}></i>
                                                    <div className="form-outline flex-fill mb-0">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            value={middlename} 
                                                            onChange={(e) => setMiddlename(e.target.value)} 
                                                        />
                                                        <label className="form-label">Middle Name</label>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row align-items-center mb-4">
                                                    <i className="fas fa-user fa-lg me-3 fa-fw" style={{ marginBottom: '30px' }}></i>
                                                    <div className="form-outline flex-fill mb-0">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            value={lastName} 
                                                            onChange={(e) => setLastName(e.target.value)} 
                                                            required 
                                                        />
                                                        <label className="form-label">Last Name</label>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row align-items-center mb-4">
                                                    <i className="fas fa-envelope fa-lg me-3 fa-fw" style={{ marginBottom: '30px' }}></i>
                                                    <div className="form-outline flex-fill mb-0">
                                                        <input 
                                                            type="email" 
                                                            className="form-control" 
                                                            value={email} 
                                                            onChange={(e) => setEmail(e.target.value)} 
                                                            required 
                                                        />
                                                        <label className="form-label">Email</label>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row align-items-center mb-4">
                                                    <i className="fas fa-lock fa-lg me-3 fa-fw" style={{ marginBottom: '30px' }}></i>
                                                    <div className="form-outline flex-fill mb-0">
                                                        <input 
                                                            type="password" 
                                                            className="form-control" 
                                                            value={password} 
                                                            onChange={(e) => setPassword(e.target.value)} 
                                                            required 
                                                        />
                                                        <label className="form-label">Password</label>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row align-items-center mb-4">
                                                    <i className="fas fa-phone fa-lg me-3 fa-fw" style={{ marginBottom: '30px' }}></i>
                                                    <div className="form-outline flex-fill mb-0">
                                                        <input 
                                                            type="tel" 
                                                            className="form-control" 
                                                            value={phoneNumber} 
                                                            onChange={(e) => setPhoneNumber(e.target.value)} 
                                                            required 
                                                        />
                                                        <label className="form-label">Phone Number</label>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center mx-4 mb-1 mb-lg-4">
                                                    <button type="submit" className="btn btn-primary btn-lg" style={{ backgroundColor: "#0a2558" }}>Register</button>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">
                                            <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp"
                                                className="img-fluid" alt="Sample image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SuperAdmin;
