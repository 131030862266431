import React, { createContext, useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    user: null,
    role: null,
    token: null
  });

  const [adminAuth, setAdminAuth] = useState({
    isAuthenticated: false,
    user: null,
    role: null,
    token: null
  });
  const [loading, setLoading] = useState(true); // New loading state
  const login = (userData) => {
    setAuth({
      isAuthenticated: true,
      user: userData.user,
      role: userData.role,
      token: userData.token,
    });

    localStorage.setItem('auth', JSON.stringify(userData));
  };
  const adminLogin = (userData) => {
    setAdminAuth({
      isAuthenticated: true,
      user: userData.user,
      role: userData.role,
      token: userData.token,
    });

    localStorage.setItem('adminAuth', JSON.stringify(userData));
  };

  const logout = () => {
    setAuth({ isAuthenticated: false, user: null, role: null, token: null });
    localStorage.removeItem('auth');
  };

  const adminLogout = () => {
    setAdminAuth({ isAuthenticated: false, user: null, role: null, token: null });
    localStorage.removeItem('adminAuth');
  };

  useEffect(() => {
    const savedAuth = localStorage.getItem('auth');
    const savedAdminAuth = localStorage.getItem('adminAuth');

    if (savedAdminAuth) {
      const parsedAuth = JSON.parse(savedAdminAuth);
      console.log("AuthContext useEffect", savedAdminAuth);
      setAuth({
        isAuthenticated: true,
        user: parsedAuth.user,
        role: parsedAuth.role,
        token: parsedAuth.token,
      });
    } else if (savedAuth) {
      const parsedAuth = JSON.parse(savedAuth);
      console.log("AuthContext useEffect", savedAuth);
      setAuth({
        isAuthenticated: true,
        user: parsedAuth.user,
        role: parsedAuth.role,
        token: parsedAuth.token,
      });
    }
    setLoading(false); // Data loading is done
  }, []);

  return (
    <>

      <AuthContext.Provider value={{ auth, adminLogin, adminAuth, adminLogout, login, logout, loading }}>
        {children}
      </AuthContext.Provider>
    </>
  );
};
