import React, { useState , useEffect } from 'react';
import '../Admin/Admin.css';
import Adminsidebar from './AdminSidebar';
import AdminMobileSidebar from '../Admin/AdminMobileSidebar';
import { useNavigate } from 'react-router-dom';
import { getApi } from '../../service/apiService';
import { capitalizeWord, formatDate } from '../../service/utils';

// Static data using the transfer object
const staticData = [
    {
        account_id: "3gE5gnRzNyfXpBK5wEEKcymJ5albGVUqg77gr",
        funding_account_id: "8945fedc-e703-463d-86b1-dc0607b55460",
        ach_class: "ppd",
        amount: "12.34",
        cancellable: true,
        created: "2020-08-06T17:27:15Z",
        description: "Desc",
        status: "pending",
        iso_currency_code: "USD",
        user: {
            email_address: "acharleston@email.com",
            legal_name: "Anne Charleston",
            phone_number: "510-555-0128",
            address: {
                street: "123 Main St.",
                city: "San Francisco",
                region: "CA",
                postal_code: "94053",
                country: "US"
            }
        },
        expected_settlement_date: "2020-08-04"
    }
];
let storeData = []
function AdminTotalTransection() {
    const [accountData, setAccountData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();

    // Simulating search functionality on static data
   

    
      const fetchGetTotalTransfers = async () => {
        setLoader(true);
        try {

          const data = await getApi(`transfer/getAll?page=${currentPage}&&limit=10`);
          console.log('TotalTransfers loaded successfully', data);
          setAccountData(data);
          storeData =data
          setLoader(false);
        } catch (error) {
          console.error('Error occurred while fetching data', error);
        //   toast.error("Error occurred while fetching data");
  
          setLoader(false);
        }
      };

    const fetchSearchAccounts = () => {
        const searchData = searchValue.trim().toLowerCase();
        if (!searchData) {
            setAccountData(storeData);
        } else {
            const filteredData = storeData.filter(account =>
                account?.clientUser?.first_name.toLowerCase().includes(searchData) ||
                account?.clientUser?.middle_name.toLowerCase().includes(searchData) ||
                account?.clientUser?.last_name.toLowerCase().includes(searchData) ||
                account?.status.toLowerCase().includes(searchData) ||
                String(account?.amount || "").includes(searchData) ||
                account?.clientUser?.email.toLowerCase().includes(searchData)
            );
            setAccountData(filteredData);
        }
        setCurrentPage(1);
    };


    const totalPages =  accountData.length  ===  10 ? currentPage +1 : currentPage ;
    const startIndex = (currentPage - 1) * 10;


    const getReportByID = (report) => {
        console.log("report" , report);
        setLoader(true);
        navigate('/account/transaction/'+report._id, { state: { report } });
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    useEffect(() => {
        fetchGetTotalTransfers();
      }, [currentPage]); 

    return (
        <>
            <div className="admin-container">
                 <Adminsidebar /> 
                <section className="home-section">
                    <AdminMobileSidebar />
                    {loader && (
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    )}
                    <div className="home-content">
                        <div className="overview-boxes">
                            <div className="box">
                                <div className="right-side">
                                    <div className="box-topic">Total Transactions</div>
                                    {/* <div className="number">{accountData && accountData?.length}</div> */}
                                    <div className="indicator">
                                        <i className='bx bx-up-arrow-alt'></i>
                                        <span className="text">Up from yesterday</span>
                                    </div>
                                </div>
                                <i className='bx bxs-cart-add cart two'></i>
                            </div>
                        </div>
                        <div className="sales-boxes">
                            <div className="recent-sales box">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="title">Total Transaction</div>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%' , height:'40px'}}>
                                        <input
                                            placeholder='Search by Name or Email , Amount , Status'
                                            value={searchValue}
                                            onChange={(e) => setSearchValue(e.target.value)}
                                        />
                                        <button
                                            className='btn btn-success mx-2'
                                            style={{ padding: '10px', border: '1px solid black', borderRadius: '10px' , textAlign:'center' }}
                                            onClick={fetchSearchAccounts}
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>

                                <table className="table table-striped" style={{ marginTop: '25px' }}>
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            {/* <th scope="col">Account ID</th>
                                            <th scope="col">Funding Account ID</th>
                                            <th scope="col">ACH Class</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Created Date</th>
                                            <th scope="col">Description</th> */}
                                            <th scope="col">User Name</th>
                                            <th scope="col">Email</th>
                                            <th scope='col'>Amount</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Paid Date</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {accountData && accountData.map((account, index) => (
                                            <tr key={index}>
                                                <th scope="row">{startIndex + index + 1}</th>
                                                {/* <td>{account.account_id}</td> */}
                                                {/* <td>{account.funding_account_id}</td>
                                                <td>{account.ach_class}</td>
                                                <td>{account.amount}</td>
                                                <td>{account.status}</td>
                                                <td>{formatDate(account.created)}</td>
                                                <td>{account.description}</td> */}
                                                 <td>{account.clientUser?.first_name || "N/A"} {account.clientUser?.middle_name}{account.clientUser?.last_name}</td>
                                                 <td>{account.clientUser?.email || "N/A"}</td>
                                                 <td>{account.amount}</td>
                                                <td>{capitalizeWord(account.status)}</td>
                                                <td>{ formatDate(account?.paidOn)  }</td>

                                                <td>
                                                    <button className="btn btn-info" onClick={() => getReportByID(account)}>
                                                        View Details
                                                    </button>
                                                </td>  
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="button">
                                    {currentPage > 1 && <button className="mx-2 btn btn-info" onClick={prevPage}>Previous</button>}
                                    {currentPage < totalPages && <button className="mx-2 btn btn-info" onClick={nextPage}>Next</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default AdminTotalTransection;
