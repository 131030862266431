let API_URL = 'https://www.mcmbankverify.com/api'  // main
// let API_URL = 'https://www.mcmbankverify.com/testtransfer'  // test stage 
// let  API_URL = 'http://localhost:8001'

const getAuthToken = () => {
    let userType = localStorage.getItem('type');
    if(userType =="user"){
        return localStorage.getItem('userToken');
    }else {
        return localStorage.getItem('token');
    }
   
}

const refreshAuthToken = async () => {
    
    localStorage.removeItem('userToken');
    localStorage.removeItem('token');
    localStorage.removeItem('auth'); 
    localStorage.removeItem('adminAuth'); 
    window.location.href = '/'; 

  
};

export const getApi = async (apiUrl, reqHeaders = {}) => {
    let token = getAuthToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token ?? 'demo'}`, // Default to 'demo' if no token
        ...reqHeaders,
    };

    try {
        const response = await fetch(`${API_URL}/${apiUrl}`, {
            method: 'GET',
            headers
        });

        if (!response.ok) {
            console.log("response" , response);
            if (response.status === 403) {
                console.log("response" , response.status);

                token = await refreshAuthToken(); // Try refreshing the token
                return getApi(apiUrl, reqHeaders); // Retry the original request with the new token
            }
            throw new Error(`Error fetching data: ${response.statusText}`);
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error in getApi:', error);
        throw error;
    }
};

export const postApi = async (apiUrl, requestBody, reqHeaders = {}) => {
    let token = getAuthToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token ?? ''}`, 
        ...reqHeaders,
    };

    try {
        const response = await fetch(`${API_URL}/${apiUrl}`, {
            method: 'POST',
            headers,
            body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
            if (response.status === 403) {
                token = await refreshAuthToken(); // Try refreshing the token
                return postApi(apiUrl, requestBody, reqHeaders); // Retry the original request with the new token
            }

            const errorDetails = await response.json().catch(() => null);
            const errorInfo = {
                status: response.status,
                statusText: response.statusText,
                url: response.url,
                errorDetails,
            };
            console.error('API Error:', errorInfo);
            throw errorInfo;
        }

        const responseBody = await response.json();
        return {
            status: response.status,
            data: responseBody,
        };

    } catch (error) {
        console.error('Network or API error:', error);
        throw error;
    }
};

// Put API with handling for token expiration (using 403 as token expiry)
export const putApi = async (apiUrl, requestBody, reqHeaders = {}) => {
    let token = getAuthToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token ?? 'demo'}`,
        ...reqHeaders,
    };

    try {
        const response = await fetch(`${API_URL}/${apiUrl}`, {
            method: 'PUT',
            headers,
            body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
            if (response.status === 403) {
                token = await refreshAuthToken(); 
                return putApi(apiUrl, requestBody, reqHeaders);
            }

            throw new Error(`Error updating data: ${response.statusText}`);
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error in putApi:', error);
        throw error;
    }
};
