import React , {useState , useEffect} from 'react'
//  import { FaDownload, FaArrowsRotate } from "react-icons/fa";
 import Header from '../Header';
 import { getApi, postApi } from '../../service/apiService';
 import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { capitalizeWord, formatDate } from '../../service/utils';
import { toast } from 'react-toastify';


function AdminTrasection() {

  const { transferId } = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [transfer , setTransfer] =useState(null);

  // Access the passed report data from state
  // const transfer = location.state?.report || {}; // Default to empty object if not passed
  
  // console.log("transferData" , transfer);




  const deleteTransaction = async () => {
    try {
      setLoader(true);
      const {data }= await postApi('transfer/delete', { transferId: transferId });
      if (data.success) {
        toast.info("Transaction Delete Successfully ");

        setTimeout(() => {
          navigate('/account/dashboard');
        }, [2000])

      } else {
        toast.error("Unable to delete Transaction");
      }
      setLoader(false);
    } catch (error) {
      console.error('Error occurred while deleting  Transaction ', error);
      toast.error("Something went wrong please try again later ");
      setLoader(false);
    }
  };


  useEffect(() => {
    if(transferId)
       fetchGetTotalTransfersById();
  }, [transferId]); 

  const fetchGetTotalTransfersById = async () => {
    setLoader(true);
    try {
      const data = await getApi(`transfer/getById/${transferId}`);
      console.log('Data fetchGetTotalTransfersById successfully', data);
      setTransfer(data);
      setLoader(false);
    } catch (error) {
      console.error('Error occurred while fetching data', error);
       toast.error("Error occurred while fetching data");
      setLoader(false);
    }
  };

  const updateTransfersById = async () => {
     setLoader(true);
    try {
      const {data }= await getApi(`transfer/update/${transferId}`);
      console.log('Data loaded successfully', data);
      setTransfer(data);
      setLoader(false);
    } catch (error) {
      toast.error("Error occurred while updating Transfers status");
      console.error('Error occurred while fetching data', error);
      setLoader(false);
    }
  };
  return (
    <div>
      <section className="">
        {/* Loader */}
        {loader && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
        <Header home="/account/dashboard" />
        <div className="overlay pt-120 pb-120">
          <div className="container wow fadeInUp">

          {transfer ?  
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-header dark-sec text-center">
                  <h3 className="text-5xl font-bold text-blue-900 text-center" > Transfer Report</h3>
                </div>
              </div>

              <div className='d-flex flex-row justify-content-between'>
                <h5 className="mx-2" style={{ marginLeft: "20px" }}>Overview</h5>
                <div>
                  <button onClick={updateTransfersById} className="btn px-2 me-2 btn-primary">
                    {/* <FaArrowsRotate /> */}
                    <span className="mx-2 text-white">Update</span>
                  </button>
                  {/* <button onClick={getReportPDF} className="btn px-3 btn-primary">
                     <FaDownload /> 
                    <span className="mx-2 text-white">Pdf</span>
                  </button> */}
                  <button className="btn p-2 px-3 mx-2 btn-danger" onClick={deleteTransaction}>Delete</button>
                </div>
              </div>

              <div className="col-lg-12 row">
                  {/* Transfer Information */}
                  <div className="col-lg-6">
                    <p>Loan Information</p>
                    <div className="container">
                      <div className="row py-2 border-bottom">
                        <div className="col">Borrower Name</div>
                        <div className="col">{transfer?.loan?.name}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col">Loan Number</div>
                        <div className="col">{transfer?.loan?.number}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col">Property Address</div>
                        <div className="col">{transfer?.loan?.propertyAddress}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col">Total Loan Amount </div>
                        <div className="col">$ {transfer?.loan?.totalLoan}</div>
                      </div>
                    </div>
                  </div>

                  {/* User Information */}
                  <div className="col-lg-6">
                    <p>User Information</p>
                    <div className="container">
                      <div className="row py-2 border-bottom">
                        <div className="col">Name</div>
                        <div className="col">{transfer?.clientUser?.first_name} {transfer?.clientUser?.middle_name} {transfer?.clientUser?.last_name}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col">Phone Number</div>
                        <div className="col">{transfer?.clientUser?.phone_number}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col">Email</div>
                        <div className="col">{transfer?.clientUser?.email}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                      <div className="col-5">Last Login</div>
                      <div className="col-7">{transfer?.clientUser?.lastLoginTime}</div>
                    </div>
                    </div>
                  </div>

                </div>

                {/* Transfer Information */}
                <div className="col-lg-12 row mt-4">
                  <p>Transfer Information</p>
                  <div className="col-lg-6">
                  
                    <div className="container">
                      <div className="row py-2 border-bottom">
                        <div className="col">Transfer ID</div>
                        <div className="col">{transferId}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col">Amount</div>
                        <div className="col">${transfer?.amount} {transfer?.iso_currency_code}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col">Status</div>
                        <div className="col">{capitalizeWord(transfer?.status)}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col">Last Activity</div>
                        <div className="col">{formatDate(transfer?.updatedAt)}</div>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-6">              
                    <div className="container">
                    <div className="row py-2 border-bottom">
                        <div className="col">Requested Date</div>
                        <div className="col">{formatDate(transfer?.requestedDate)}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col"> Due Date</div>
                        <div className="col">{formatDate(transfer?.dueDate)}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col"> Paid Date</div>
                        <div className="col">{formatDate(transfer?.paidOn)}</div>
                      </div>
                    </div>
                  </div>   
                </div>
              <div className="col-lg-12 row">
              <p className='mt-4' >Requested by Admin  </p>             
              <div className="col-lg-6">
                  <div className="container">
                    <div className="row py-2 border-bottom">
                      <div className="col-5">Name</div>
                      <div className="col-7">{transfer?.requestedByAdminUser?.first_name} {transfer?.requestedByAdminUser?.last_name}</div>
                    </div>
                  </div>
                </div> 
                <div className="col-lg-6">
                  <div className="container">
                    <div className="row py-2 border-bottom">
                      <div className="col-5">Email</div>
                      <div className="col-7">{transfer?.requestedByAdminUser?.email}</div>
                    </div>
                  </div>
                </div> 
              </div>

              {/* Transfer Table */}
              <div className="col-lg-12" style={{ marginTop: '25px' }}>
                <p>Transfer Summary</p>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">ACH class</th>
                      <th scope="col">Network</th>
                      <th scope="col">Description</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{transfer?.TransferIntentResponse?.ach_class || "N/A"}</td>
                      <td>{transfer?.TransferIntentResponse?.network || "N/A"}</td>
                      <td>{transfer?.TransferIntentResponse?.description ||  "N/A"}</td>
                      <td>${transfer?.TransferIntentResponse?.amount || "N/A"}</td>
                      <td>{capitalizeWord(transfer?.TransferIntentResponse?.status ||"N/A")}</td> 
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> :
            <div>
              No Transactions Found
            </div>
            }
          </div>
        </div>
      </section>
    </div>
  );
}

export default AdminTrasection;
