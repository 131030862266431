import React, { useState  , useEffect } from 'react';
import '../Admin/Admin.css';
import UserSidebar from './UserSidebar';
import { useNavigate } from 'react-router-dom';
import UserMobileSidebar from './UserMobileSidebar';
import { capitalizeWord, formatDate } from '../../service/utils';
import { getApi } from '../../service/apiService';
import DisplayCard from './DisplayCard';

// Static data using the transfer object

let storeData = [];

function UserTotalTrasection() {
    const [accountData, setAccountData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();

    // Simulating search functionality on static data
    const fetchSearchAccounts = () => {
        const searchData = searchValue.trim().toLowerCase();
        if (searchData) {
            const filteredData = storeData.filter(account =>
                account?.loan?.name.toLowerCase().includes(searchData) ||
                account?.loan?.propertyAddress.toLowerCase().includes(searchData) ||
                account?.status.toLowerCase().includes(searchData)||
                account?._id.toLowerCase().includes(searchData) ||
                account?.loan?.number.toLowerCase().includes(searchData) 
            );
            setAccountData(filteredData);
        }else{
            GetAllTransferByUser()
        }
        setCurrentPage(1);
    };

    const totalPages = (accountData.length == 10 ) ?currentPage +1 : currentPage ;
    const startIndex = (currentPage - 1) * 10;

    const getTransferByID = (report) => {
        // setLoader(true);
        navigate('/user/transactionById', { state: { transfer_id: report._id } });
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const GetAllTransferByUser = async () => {
        try {
          const data  = await getApi(`client/transfer/all?limit=10&&page=${currentPage}`);
          storeData =  data
          setAccountData(data)
        } catch (error) {
          // toast(" Internal server Error please try again some time later ");
          // console.log("error", error);
        }
      };

    useEffect(()=>{
        const token = localStorage.getItem('token');
        const userId =   localStorage.getItem("userId") 
        if(!token && !userId){
         navigate('/user/login')
        }else{
            GetAllTransferByUser()
        }
     
       },[currentPage])

    return (
        <>
            <div className="admin-container">
            {/* <UserSidebar /> */}
                <section >
                    <UserMobileSidebar />
                    {loader && (
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    )}
                    <div className="home-content">
                        <div className="overview-boxes">
                   <DisplayCard title="Total Transaction"  value={accountData?.length || 0}  />

                            {/* <div className="box">
                                <div className="right-side">
                                    <div className="box-topic">Total Transaction</div>
                                    <div className="number">{accountData?.length}</div>
                                    <div className="indicator">
                                        <i className='bx bx-up-arrow-alt'></i>
                                        <span className="text">Up from yesterday</span>
                                    </div>
                                </div>
                                <i className='bx bxs-cart-add cart two'></i>
                            </div> */}
                        </div>
                        <div className="sales-boxes">
                            <div className="recent-sales box">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="title">Total Transaction</div>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%' , height:'40px'}}>
                                        <input
                                            placeholder='Search by Name or Email'
                                            value={searchValue}
                                            onChange={(e) => setSearchValue(e.target.value)}
                                        />
                                        <button
                                            className='btn btn-success mx-2'
                                            style={{ padding: '10px', border: '1px solid black', borderRadius: '10px' , textAlign:'center' }}
                                            onClick={fetchSearchAccounts}
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>

                                <table className="table table-striped" style={{ marginTop: '25px' }}>
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            {/* <th scope="col">Account ID</th>
                                            <th scope="col">Funding Account ID</th>
                                            <th scope="col">ACH Class</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Created Date</th>
                                            <th scope="col">Description</th> */}
                                            {/* <th scope="col">Borrower Name </th> */}
                                            <th scope="col">Loan number </th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Requested Date</th>
                                            <th scope="col">Property Address</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {accountData.map((account, index) => (
                                            <tr key={index}>
                                                <th scope="row">{startIndex + index + 1}</th>
                                                 {/* <td>{account?.loan?.name || "N/A" }</td>  */}
                                                 <td>{account?.loan?.number ||"N/A" }</td> 
                                                <td>{account.amount}</td>
                                                <td>{capitalizeWord(account.status)}</td>
                                                <td>{formatDate(account.requestedDate)}</td>
                                                <td className="truncate" >{account?.loan?.propertyAddress ||"N/A" }</td> 

                                                {/*<td>{account.ach_class}</td>
                                                <td>{account.amount}</td>
                                                <td>{account.status}</td>
                                                <td>{formatDate(account.created)}</td>
                                                <td>{account.description}</td> */}
                                                {/* <td>{account.user.legal_name}</td>
                                                <td>{account.user.email_address}</td>
                                                <td>{account.user.phone_number}</td>
                                                <td>{formatDate(account.requestedDate)}</td> */}
                                                <td>
                                                    <button className="btn btn-info" onClick={() => getTransferByID(account)}>
                                                        View Details
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="button">
                                    {currentPage > 1 && <button className="mx-2 btn btn-info" onClick={prevPage}>Previous</button>}
                                    {currentPage < totalPages && <button className="mx-2 btn btn-info" onClick={nextPage}>Next</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default UserTotalTrasection;


