import React, { Suspense ,lazy } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminTrasection from '../Componets/AdminAccounts/AdminTrasection';
import AdminTotalTransection from '../Componets/AdminAccounts/AdminTotalTransection';
import UserSetting from '../Componets/Users/UserSetting';
import UserTotalTrasection from '../Componets/Users/UserTotalTransection';
import UserTransectionById from '../Componets/Users/UserTransectionById';
// import TotalUsers from '../Componets/AdminAccounts/TotalUsers'
import AdminAccsessUser from '../Componets/AdminAccounts/AdminUserAccsess';
import UserTotalTranfer from '../Componets/AdminAccounts/UserTotalTransection';
import RegisterAdmin from '../Componets/AdminAccounts/RegisterAdmin';
import RequestTranssection from '../Componets/AdminAccounts/RequestTranssection';
import AdminRequestSetting from '../Componets/AdminAccounts/AdminRequestSetting';
// import UserLogin from '../Componets/Users/UserLogin';
import UserRegister from '../Componets/Users/UserRegister';
import ProtectedRoute from './ProtectedRoute';
import SuperAdmin from '../Componets/SuperAdmin/SuperAdmin';
import PaymentPage from '../Componets/Users/PaymentPage';
import CreateTransferRequest from '../Componets/AdminAccounts/CreateTransferRequest';
import UserNotification from '../Componets/AdminAccounts/UserNotification';

const AdminNewLogin = lazy(()=>import('../Componets/Admin/AdminNewLogin'))

const UserDashboard = lazy(()=>import('../Componets/Users/UserDashboard'))
const Login = lazy(() => import('../Pages/Login'));
const Dashboard = lazy(() => import('../Pages/Dashboard'));
const Register = lazy(() => import('../Pages/Register'));
const Accountreport = lazy(() => import('../Pages/Accountreport'));
const AdminHome = lazy(() => import('../Componets/Admin/AdminHome'));
const ShowDeteils = lazy(() => import('../Pages/ShowDeteils'));
const ErrorPage = lazy(() => import('../Componets/Admin/ErrorPage'));
const AdminUserList = lazy(() => import('../Componets/Admin/AdminUserList'));
const AccountUserDeteils = lazy(() => import('../Componets/Admin/AccountUserDeteils'));
const NewConnecteUser = lazy(() => import('../Componets/Admin/NewConnecteUser'));
const AdminSetting = lazy(() => import('../Componets/Admin/AdminSetting'));
const AssetReportPage = lazy(() => import('../Componets/AssetReport'));
const RequestReport = lazy(() => import('../Pages/RequestReport'));
const AdminDashBoard = lazy(() => import('../Componets/AdminAccounts/AdminDashboard'));
const UserLogin =  lazy(() => import('../Componets/Users/UserLogin'));
// const UserRegister = lazy(() => import('../Componets/Users/UserRegister'));
const UserForgetPass = lazy(() => import('../Componets/Users/UserForgetPass'));
const TotalUsers = lazy(()=>import('../Componets/AdminAccounts/TotalUsers'))

  function PublicRoutes() {
    return (
      <>
        <Router >
        <Suspense fallback={<>
        <div className="loader-container">
            <div className="loader"></div>
          </div></>}>
          <Routes>
            <Route  index path='/' element={<Dashboard /> } />
            {/* user pages */}
            <Route path='/user/login' element={<UserLogin/>} />
            <Route path='/user/signup' element={<UserRegister/>} />
            <Route path='/forgot/password' element={<UserForgetPass/>} />
            <Route path='user' element={<ProtectedRoute role={["user"]} type="user"  />}>
              <Route path='dashboard' element={<UserDashboard />} />
              <Route path='transaction' element={<UserTotalTrasection/>} />
              <Route path='setting' element={<UserSetting/>} />
              <Route path='transactionById' element={<UserTransectionById/>} />
            </Route>

            {/* admin pages */}
            <Route path='/login' element={<Login />} />
            <Route path='section' element={<ProtectedRoute />}>
              <Route path='selector' element={<AdminNewLogin/>} />
            </Route>

            {/* report section */}
            <Route path='report' element={<ProtectedRoute role={['report' , 'admin']}  />}>
              <Route path='dashboard' element={<AdminHome/>} />
              <Route path='all' element={<AccountUserDeteils/>} />
              <Route path='accounts' element={<NewConnecteUser/>} />
              <Route path='request' element={<RequestReport />} />
              <Route path='view' element={<Accountreport />} />
              <Route path='create' element={<AssetReportPage />} />   
              <Route path='details' element={<ShowDeteils/>} />
            </Route>

            {/* account section */}
            <Route path='account' element={<ProtectedRoute role={['account','admin']} />}>
              <Route path='dashboard' element={<AdminDashBoard/>} />
              <Route path='transactions' element={<AdminTotalTransection/>} />
              <Route path='requests' element={<RequestTranssection/>} />
              <Route path='transaction/:transferId' element={<AdminTrasection/>} />
              <Route path='accounts' element={<TotalUsers/>} />
              <Route path='transaction/create/:userId' element={<CreateTransferRequest/>} />
            </Route>

             {/* admin  section */}
            <Route path='admin' element={<ProtectedRoute role={['admin']}  />}>
              <Route path='setting' element={<AdminRequestSetting/>} />
              <Route path='all' element={<AdminUserList/>} />  {/*  not in use */}
            </Route>
           
            <Route path='/404' element={<ErrorPage/>} />
            {/* <Route path='/settings' element={<AdminSetting/>} />         */}

            {/* not in use */}
            <Route path='/pp' element={<PaymentPage />} />
            <Route path='/superAdmin/superadmin' element={<SuperAdmin/>} /> 
          
            <Route path='/userAdminAcsess' element={<AdminAccsessUser/>} />
            <Route path='/userTotaltranfer' element={<UserTotalTranfer/>} />
            <Route path='/registerAdmin' element={<RegisterAdmin/>} />
            <Route path='/alert' element={<UserNotification/>} />

          </Routes>
          </Suspense>
        </Router>
      </>
    )
  }

  export default PublicRoutes