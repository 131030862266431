


import React, { useState, useEffect } from 'react';
import '../Admin/Admin.css';
import Adminsidebar from './AdminSidebar';
import AdminMobileSidebar from '../Admin/AdminMobileSidebar';
import { useNavigate } from 'react-router-dom';
import { getApi, postApi } from '../../service/apiService';
import { formatDate } from '../../service/utils';
import { toast } from 'react-toastify';

// Static data using the transfer object
const staticData = [
    {
        account_id: "3gE5gnRzNyfXpBK5wEEKcymJ5albGVUqg77gr",
        funding_account_id: "8945fedc-e703-463d-86b1-dc0607b55460",
        ach_class: "ppd",
        amount: "12.34",
        cancellable: true,
        created: "2020-08-06T17:27:15Z",
        description: "Desc",
        status: "pending",
        iso_currency_code: "USD",
        user: {
            email_address: "acharleston@email.com",
            legal_name: "Anne Charleston",
            phone_number: "510-555-0128",
            address: {
                street: "123 Main St.",
                city: "San Francisco",
                region: "CA",
                postal_code: "94053",
                country: "US"
            }
        },
        expected_settlement_date: "2020-08-04"
    }
];

let storeDate = []

function RequestTranssection() {
    const [accountData, setAccountData] = useState(staticData);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();

    // Simulating search functionality on static data

    const fetchGetTotalTransfers = async () => {
        setLoader(true);
        try {
            // Fetch transfer data
            const transferData = await getApi(`transfer/Requested/getAll?page=${currentPage}&&limit=10`);
            // console.log('Data loaded successfully', transferData);
            storeDate = transferData
            // Extract client IDs and fetch their details

            setAccountData(transferData);
        } catch (error) {
            console.error('Error occurred while fetching transfer data', error);
        } finally {
            setLoader(false);
        }
    };

    const fetchSearchAccounts = () => {
        const searchData = searchValue.trim().toLowerCase();
        if (!searchData) {
            setAccountData(storeDate);
        } else {
            const filteredData = storeDate.filter(account =>
                account?.clientUser?.first_name.toLowerCase().includes(searchData) ||
                account?.clientUser?.middle_name.toLowerCase().includes(searchData) ||
                account?.clientUser?.last_name.toLowerCase().includes(searchData) ||
                account?.clientUser?.email.toLowerCase().includes(searchData) ||
                String(account?.amount || "").includes(searchData)
            );
            setAccountData(filteredData);
        }
        setCurrentPage(1);
    };

    const totalPages = accountData.length == 10 ? currentPage + 1 : currentPage;
    const startIndex = (currentPage - 1) * 10;

    const getReportByID = (report) => {
        console.log(report)
        setLoader(true);
        navigate('/account/transaction/' + report._id, { state: { report } });
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const deleteTransaction = async (transferId) => {
        try {
            setLoader(true);
            const { data } = await postApi('transfer/delete', { transferId: transferId });
            if (data.success) {
                toast.info("Transaction Delete Successfully ");
                fetchGetTotalTransfers();
            } else {
                toast.error("Unable to delete Transaction");
            }
            setLoader(false);
        } catch (error) {
            console.error('Error occurred while deleting  Transaction ', error);
            toast.error("Something went wrong please try again later ");
            setLoader(false);
        }
    };

    useEffect(() => {
        fetchGetTotalTransfers();
    }, [currentPage]);

    return (
        <>
            <div className="admin-container">
                <Adminsidebar />
                <section className="home-section">
                    <AdminMobileSidebar />
                    {loader && (
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    )}
                    <div className="home-content">
                        <div className="overview-boxes">
                            <div className="box">
                                <div className="right-side">
                                    <div className="box-topic">Total Request Transaction</div>
                                    {/* <div className="number">{accountData.length}</div> */}
                                    <div className="indicator">
                                        <i className='bx bx-up-arrow-alt'></i>
                                        <span className="text">Up from yesterday</span>
                                    </div>
                                </div>
                                <i className='bx bxs-cart-add cart two'></i>
                            </div>
                        </div>
                        <div className="sales-boxes">
                            <div className="recent-sales box">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="title">Total Request Transaction</div>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%', height: '40px' }}>
                                        <input
                                            placeholder='Search by Name or Email , Amount'
                                            value={searchValue}
                                            onChange={(e) => setSearchValue(e.target.value)}
                                        />
                                        <button
                                            className='btn btn-success mx-3'
                                            style={{ padding: '5px 8px', border: '1px solid black', borderRadius: '10px', textAlign: 'center' }}
                                            onClick={fetchSearchAccounts}
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>

                                <table className="table table-striped" style={{ marginTop: '25px' }}>
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            {/* <th scope="col">Account ID</th>
                                            <th scope="col">Funding Account ID</th>
                                            <th scope="col">ACH Class</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Created Date</th>
                                            <th scope="col">Description</th> */}
                                            <th scope="col">User Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Amount</th>
                                            {/* <th scope="col">Created</th> */}
                                            <th scope="col">Due Date</th>
                                            <th scope="col">Action</th>
                                            {/* <th scope="col">Delete</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {accountData && accountData.map((account, index) => (
                                            <tr key={index}>
                                                <th scope="row">{startIndex + index + 1}</th>

                                                <td>{account.clientUser?.first_name || "N/A"} {account.clientUser?.middle_name} {account.clientUser?.last_name}</td>
                                                <td>{account.clientUser?.email || "N/A"}</td>
                                                <td>{account.amount}</td>
                                                {/* <td>{formatDate(account.requestedDate)}</td> */}
                                                <td>{formatDate(account.dueDate)}</td>

                                                <td>
                                                    <button className="btn  btn-info" onClick={() => getReportByID(account)}>
                                                        View
                                                    </button>
                                                    <button className="btn ms-2 px-3  btn-danger" onClick={() => { deleteTransaction(account._id) }}>Delete</button>

                                                </td>
                                                {/* <td scope="col">
                                                </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="button">
                                    {currentPage > 1 && <button className="mx-2 btn btn-info" onClick={prevPage}>Previous</button>}
                                    {currentPage < totalPages && <button className="mx-2 btn btn-info" onClick={nextPage}>Next</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default RequestTranssection;
