import React, { useState, useEffect } from 'react';
import Adminsidebar from './AdminSidebar';
import AdminMobileSidebar from './AdminMobileSidebar';
import './AdminAccount.css';
import { useNavigate } from 'react-router-dom';
import { getApi, postApi, putApi } from '../../service/apiService';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from 'mdb-react-ui-kit';
import { toast, ToastContainer } from 'react-toastify';
import { MdDelete } from "react-icons/md";
import { HiInformationCircle } from "react-icons/hi";

let storeData = []

function AdminRequestSetting() {
  const [accountData, setAccountData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [loader, setLoader] = useState(false);
  // const [deleteModel , setDeleteModel] = useState(false);
  const [newUser, setNewUser] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
    access_control: [],
  });
  const [accessOptions, setAccessOptions] = useState(['account', 'report']);
  const [modalType, setModalType] = useState('add');
  const [selectedUser, setSelectedUser] = useState(null);

  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

const openDeleteModal = (user) => {
  setUserToDelete(user);
  setModalOpen(false);
  setDeleteModel(true);

};

const confirmDelete = async () => {
  try {
    console.log("userToDelete._id", userToDelete._id);

    await postApi(`users/delete`, { userId: userToDelete._id });

    toast.success('User deleted successfully');
    fetchAllUsers(); 
    setDeleteModel(false);
    setUserToDelete(null);
  } catch (error) {
    console.error('Error deleting user:', error);
    toast.error('Failed to delete user');
  }
};

const handleEditClick = (e) => {
  e.preventDefault();
  setIsEditMode(true);  // Switch to edit mode
};

  const fetchAllUsers = async () => {
    setLoader(true);
    try {
      const apiData = await getApi(`users/allUsers?page=${currentPage}`, {
        headers: { 'Content-Type': 'application/json' },
      });
      storeData = apiData
      setAccountData(apiData);
    } catch (error) {
      console.error('Error fetching users:', error);
      toast.error('Failed to fetch users');
    } finally {
      setLoader(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleAccessChange = (option) => {
    setNewUser((prev) => {
      const updatedAccess = prev.access_control.includes(option)
        ? prev.access_control.filter((item) => item !== option)
        : [...prev.access_control, option];
      return { ...prev, access_control: updatedAccess };
    });
  };

  const openAddUserModal = () => {
    setModalType('add');
    setNewUser({
      first_name: '',
      middle_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      password: '',
      access_control: [],
    });
    setModalOpen(true);
  };

  // const openEditUserModal = (user) => {
  //   setModalType('edit');
  //   setSelectedUser(user);
  //   setNewUser({
  //     _id:user._id ,
  //     first_name: user.first_name,
  //     middle_name: user.middle_name,
  //     last_name: user.last_name,
  //     email: user.email,
  //     phone_number: user.phone_number,
  //     password: '', 
  //     access_control: user.access_control || [],
  //   });
  //   setModalOpen(true);
  // };

  const openEditUserModal = (user) => {
    setModalType('edit');
    setSelectedUser(user);
    setNewUser({
      _id: user._id,
      first_name: user.first_name,
      middle_name: user.middle_name,
      last_name: user.last_name,
      email: user.email,
      phone_number: user.phone_number,
      password: '',
      access_control: user.access_control || [],
    });
    setIsEditMode(false);  // Start in view-only mode
    setModalOpen(true);
  };
  


  const handleSaveUser = async (e) => {
    e.preventDefault(); // Prevent form from refreshing the page

    console.log("selectedUser" ,selectedUser)
    try {
      if (modalType === 'add') {
        await postApi('users/signup', newUser);
        toast.success('User added successfully');
      } else if (modalType === 'edit') {
        await putApi(`users/userUpdate/${selectedUser._id}`, newUser);
        toast.success('User updated successfully');
      }
      fetchAllUsers();
      setModalOpen(false);
    } catch (error) {
      console.error('Error saving user:', error);
      toast.error('An error occurred while saving the user.');
    }
  };


  const handleSearch = () => {
    let searchData = searchValue.trim().toLowerCase()
    setAccountData(
      searchData
        ? storeData.filter(
            (user) =>
              (user?.first_name && user?.first_name.toLowerCase().includes(searchData)) ||
              (user?.middle_name && user?.middle_name.toLowerCase().includes(searchData)) ||
              (user?.last_name && user?.last_name.toLowerCase().includes(searchData)) ||
              (user?.phone_number && user?.phone_number.toLowerCase().includes(searchData)) ||
              (user?.email && user?.email.toLowerCase().includes(searchData))
          )
        : storeData
    );
    setCurrentPage(1);
  };



  const totalPages = accountData.length === 10  ?currentPage+1 :currentPage;
  const startIndex = (currentPage - 1) * 10;


  const handleDelete =()=>{
    setDeleteModel(true);
  }

  useEffect(() => {
    fetchAllUsers();
  }, [currentPage]);

  return (
    <>
      <div className="admin-container">
        <Adminsidebar />
        <section className="home-section">
          <AdminMobileSidebar />
          <div className="home-content">
            <div className="boxcardcontainer">
              <div className="overview-boxes" style={{ width: '100%' }}>
                <div className="box" style={{ width: 'calc(100% / 3)' }}>
                  <div className="right-side">
                    <div className="box-topic">Total Users</div>
                    {/* <div className="number">{accountData.length}</div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="sales-boxes">
              <div className="recent-sales box">
                <div className='searchUserSection'>
                  <div className="title">
                    User Access
                  
                  </div>
                   <div className='serchInputSection'>
                    
                            <input type="text" id="simple-search" 
                               placeholder="Search by Name or Email ,Phone Number"
                               value={searchValue}
                               onChange={(e) => setSearchValue(e.target.value)}
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" style={{height:'40px'}} />

                     <button style={{height:'40px'}} className="btn btn-success mx-3" onClick={handleSearch}>
                      Search 
                    </button> 
                  
                  </div> 
                  <div>
                  <button class="btn btn-primary " style={{textWrap:'nowrap' , height:'40px'}} onClick={openAddUserModal}>
                      Add  User
                    </button>
                  </div>
                </div>
                <table className="table table-striped" style={{ marginTop: '25px' }}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>User Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accountData.map((user, index) => (
                      <tr key={user.id}>
                        <td>{startIndex+ index + 1}</td>
                        <td>
                          {user.first_name} {user.middle_name} {user.last_name}
                        </td>
                        <td>{user.email}</td>
                        <td>{user.phone_number}</td>
                        <td>
                          <button
                            className="btn btn-warning mx-2"
                            onClick={() => openEditUserModal(user)}
                          >
                           <span style={{display:'flex' , }}> View <HiInformationCircle  style={{marginLeft:'5px' , marginTop:'5px'}} /></span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="button">
                  {currentPage > 1 && (
                    <button className="mx-2 btn btn-primary" onClick={() => setCurrentPage((prev) => prev - 1)}>
                      Previous
                    </button>
                  )}
                  {currentPage < totalPages && (
                    <button className="mx-2  btn btn-primary" onClick={() => setCurrentPage((prev) => prev + 1)}>
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <MDBModal open={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
  <MDBModalDialog>
    <MDBModalContent>
      <MDBModalHeader>
        <MDBModalTitle>{modalType === 'add' ? 'Add New User' : 'User Information'}</MDBModalTitle>
        <MDBBtn className="btn-close" color="none" onClick={() => setModalOpen(false)} />
      </MDBModalHeader>
      <MDBModalBody>
        <form>
          {/* Loop through the fields and render them */}
          {['first_name', 'middle_name', 'last_name', 'email', 'phone_number', 'password'].map((field) => (
            <div className="form-group" key={field}>
              <label>{field.replace('_', ' ').toUpperCase()}:</label>
              <input
                type={field === 'password' ? 'password' : 'text'}
                name={field}
                value={newUser[field]}
                onChange={handleInputChange}
                className="form-control"
                disabled={modalType === 'add' ? false : !isEditMode}  // Directly enable inputs in 'add' mode
              />
            </div>
          ))}

          {/* Access Features */}
          <div className="form-group my-2">
            <label>Access Features:</label>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              {accessOptions.map((option, index) => (
                <div className="flex items-center mb-4" key={index}>
                  <input
                    id={`access-${index}`}
                    type="checkbox"
                    checked={newUser.access_control.includes(option)}
                    onChange={() => handleAccessChange(option)}
                    disabled={modalType === 'add' ? false : !isEditMode}  // Directly enable checkboxes in 'add' mode
                    className="w-8 h-16 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                  />
                  <label htmlFor={`access-${index}`} className="ms-2 text-lg font-medium">
                    {option}
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/* Buttons */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {modalType === 'add' ? (
              <button type="button" className="btn btn-primary" onClick={handleSaveUser}>Save</button>
            ) : (
              <>
                {!isEditMode ? (
                  <button type="button" className="btn btn-warning" onClick={handleEditClick}>Edit</button>
                ) : (
                  <>
                    <button className="savebtnmodel" onClick={handleSaveUser}>Save</button>
                    <button type="button" className="deletebtnmodel" onClick={() => openDeleteModal(newUser)}>Delete</button>
                  </>
                )}
              </>
            )}
          </div>
        </form>
      </MDBModalBody>
    </MDBModalContent>
  </MDBModalDialog>
</MDBModal>

        <MDBModal open={deleteModel} toggle={() => setDeleteModel(!deleteModel)}>
  <MDBModalDialog>
    <MDBModalContent>
      <MDBModalHeader>
        <MDBModalTitle>Confirm Deletion</MDBModalTitle>
        <MDBBtn className="btn-close" color="none" onClick={() => setDeleteModel(false)} />
      </MDBModalHeader>
      <MDBModalBody>
        Are you sure you want to delete  {userToDelete?.first_name} ?
      </MDBModalBody>
      <div className="d-flex justify-content-around my-3">
       
        <button type="button" class="btn btn-danger" onClick={confirmDelete} >          Delete
  </button>
        <button type="button" class="btn btn-secondary" onClick={() => setDeleteModel(false)} >      Cancel
        </button>
      
      </div>
    </MDBModalContent>
  </MDBModalDialog>
</MDBModal>

      </div>
    </>
  );
}

export default AdminRequestSetting;
