import React, { useState, useEffect } from 'react';
import './AdminAccount.css';
import { Link, useNavigate } from 'react-router-dom';
import Adminsidebar from './AdminSidebar';
import AdminMobileSidebar from './AdminMobileSidebar';
import { getApi, postApi } from '../../service/apiService';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

function AdminAccsessUser() {
    const [accountData, setAccountData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [amount, setAmount] = useState(0);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const location = useLocation();
    const userInfo = location.state; // Access the state (userData) passed via navigate

    useEffect(() => {
        if (userInfo) {
            console.log("User Data:", userInfo);
            // Do something with userData
        }
    }, [userInfo]);

    const dummyTransactionData = [
        { transaction_id: 1, user_name: 'John Doe', amount: 120.50, transaction_date: '2024-09-01', status: 'Successful' },
        { transaction_id: 2, user_name: 'Jane Smith', amount: 89.99, transaction_date: '2024-08-30', status: 'Failed' },
        { transaction_id: 3, user_name: 'Bob Johnson', amount: 45.75, transaction_date: '2024-08-29', status: 'Pending' },
        { transaction_id: 4, user_name: 'Alice Davis', amount: 200.00, transaction_date: '2024-08-25', status: 'Successful' },
        { transaction_id: 5, user_name: 'Michael Brown', amount: 150.25, transaction_date: '2024-08-20', status: 'Successful' }
    ];

    const fetchSearchAccounts = () => {
        const searchData = searchValue.trim();
        if (!searchData) {
            setAccountData(dummyTransactionData);
        } else {
            const filteredData = dummyTransactionData.filter(account =>
                account.user_name.toLowerCase().includes(searchData.toLowerCase())
            );
            setAccountData(filteredData);
        }
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(dummyTransactionData.length / 5);
    const startIndex = (currentPage - 1) * 5;
    const endIndex = startIndex + 5;
    const currentData = dummyTransactionData.slice(startIndex, endIndex);

    const nextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(prevPage => prevPage - 1);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };

    const handleSubmitTransferRequest = async () => {
        try {
            setLoading(true);
            const response = await postApi('transfer/createRequest', { amount, clientUser: userInfo._id });

            if (response.status === 200) {
                // // console.log('Login successful', response);
                // // console.log('Login successful', response.data.token);
                let data = response.data.data
                localStorage.setItem('token', data.token);
                setLoading(false);
                toast('Transfer Request Created  ');
            } else {
                // setError( 'An error occurred while logging in');
                toast('Please enter a correct email and password');
                setLoading(false);
            }
        } catch (error) {
            console.error('Error occurred while logging in', error);
            // setError('Something went wrong, please try again');
            toast('Something went wrong, please try again');
            setLoading(false);
        }
    };

    const showAllTransection = () => {
        navigate('/userTotaltranfer')
    }

    return (
        <>

            <Adminsidebar />
            <section className="home-section">
                <AdminMobileSidebar />
                {loading &&
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                }


                <div className="overlay pt-120 pb-120">
                    <div className="container wow fadeInUp">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="section-header dark-sec text-center">
                                    <h3 className="text-5xl font-bold text-blue-900 text-center"  > User-Information</h3>
                                </div>
                            </div>
                            <div className="col-lg-12 row">
                                <div className="col-lg-6 ">
                                    <h5 className="my-3" >User Information .</h5>
                                    {/* <p className=''>User Information</p> */}
                                    <div class="container">
                                        <div class="row  py-2 border-bottom ">
                                            <div class="col">First Name</div>
                                            <div class="col">{userInfo?.first_name}</div>
                                        </div>
                                        <div class="row py-2 border-bottom">
                                            <div class="col">Middle Name</div>
                                            <div class="col">{userInfo?.middle_name
                                            }</div>
                                        </div>
                                        <div class="row py-2 border-bottom">
                                            <div class="col">Last Name</div>
                                            <div class="col">{userInfo?.
                                                last_name
                                            }</div>
                                        </div>
                                        <div class="row py-2 border-bottom">
                                            <div class="col">Email </div>
                                            <div class="col">{userInfo.email
                                            }</div>
                                        </div>
                                        <div class="row py-2 border-bottom">
                                            <div class="col">Accounts</div>
                                            <div class="col">5</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 " style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', border: '1px solid white', borderRadius: '10px' }}>
                                    <div className="send-money d-flex flex-column " style={{ minHeight: "300px", padding: '15px' }}>
                                        {/* <div > */}
                                        <div className="py-2 d-flex justify-content-between ">
                                            <div className="left-side">
                                                <span className="xlr fw-bold">Create Transfer Request</span>
                                            </div>
                                        </div>


                                        <div className="items-center">
                                            <span className="xl m-2">Enter Requested Amount</span>
                                        </div>
                                        <div className="btn-area mt-1">
                                            <div className=" mb-4">
                                                <input placeholder='Enter Amount' type="number" value={amount} onChange={e => setAmount(e.target.value)} />
                                                {/* <div className="w-full" >
                                                       
                                                    </div> */}
                                            </div>
                                            <button onClick={handleSubmitTransferRequest} disabled={amount <= 0 || loading} className="btn cmn-btn  w-100"> Create Request</button>
                                            <button className="btn cmn-btn w-100 my-2" style={{ marginTop: '30px' }}>Login in user account </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default AdminAccsessUser;
