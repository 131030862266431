


import React, { useState, useEffect } from 'react';
import './AdminAccount.css';
import { Link, useNavigate } from 'react-router-dom';
import Adminsidebar from './AdminSidebar';
import AdminMobileSidebar from './AdminMobileSidebar';
import { getApi } from '../../service/apiService';
import { useLocation } from 'react-router-dom';
import { capitalizeWord } from '../../service/utils';

function UserTotalTranfer() {
    const [accountData, setAccountData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [loader , setLoader] = useState(false);
    const [data , setData] = useState([]);
    const navigate = useNavigate();

    const location = useLocation();
    const userInfo = location.state; // Access the state (report) passed via navigate

    useEffect(() => {
        if (userInfo) {
            console.log("User Data:", userInfo);
            // Do something with userData
        }
    }, [userInfo]);

    const dummyTransactionData = [
        { transaction_id: 1, user_name: 'John Doe', amount: 120.50, transaction_date: '2024-09-01', status: 'Successful' },
        { transaction_id: 2, user_name: 'Jane Smith', amount: 89.99, transaction_date: '2024-08-30', status: 'Failed' },
        { transaction_id: 3, user_name: 'Bob Johnson', amount: 45.75, transaction_date: '2024-08-29', status: 'Pending' },
        { transaction_id: 4, user_name: 'Alice Davis', amount: 200.00, transaction_date: '2024-08-25', status: 'Successful' },
        { transaction_id: 5, user_name: 'Michael Brown', amount: 150.25, transaction_date: '2024-08-20', status: 'Successful' },
        { transaction_id: 1, user_name: 'John Doe', amount: 120.50, transaction_date: '2024-09-01', status: 'Successful' },
        { transaction_id: 2, user_name: 'Jane Smith', amount: 89.99, transaction_date: '2024-08-30', status: 'Failed' },
        { transaction_id: 3, user_name: 'Bob Johnson', amount: 45.75, transaction_date: '2024-08-29', status: 'Pending' },
        { transaction_id: 4, user_name: 'Alice Davis', amount: 200.00, transaction_date: '2024-08-25', status: 'Successful' },
        { transaction_id: 5, user_name: 'Michael Brown', amount: 150.25, transaction_date: '2024-08-20', status: 'Successful' },
        { transaction_id: 1, user_name: 'John Doe', amount: 120.50, transaction_date: '2024-09-01', status: 'Successful' },
        { transaction_id: 2, user_name: 'Jane Smith', amount: 89.99, transaction_date: '2024-08-30', status: 'Failed' },
        { transaction_id: 3, user_name: 'Bob Johnson', amount: 45.75, transaction_date: '2024-08-29', status: 'Pending' },
        { transaction_id: 4, user_name: 'Alice Davis', amount: 200.00, transaction_date: '2024-08-25', status: 'Successful' },
        { transaction_id: 5, user_name: 'Michael Brown', amount: 150.25, transaction_date: '2024-08-20', status: 'Successful' },
        { transaction_id: 1, user_name: 'John Doe', amount: 120.50, transaction_date: '2024-09-01', status: 'Successful' },
        { transaction_id: 2, user_name: 'Jane Smith', amount: 89.99, transaction_date: '2024-08-30', status: 'Failed' },
        { transaction_id: 3, user_name: 'Bob Johnson', amount: 45.75, transaction_date: '2024-08-29', status: 'Pending' },
        { transaction_id: 4, user_name: 'Alice Davis', amount: 200.00, transaction_date: '2024-08-25', status: 'Successful' },
        { transaction_id: 5, user_name: 'Michael Brown', amount: 150.25, transaction_date: '2024-08-20', status: 'Successful' }
    ];

    const fetchSearchAccounts = () => {
        const searchData = searchValue.trim();
        if (!searchData) {
            setAccountData(dummyTransactionData);
        } else {
            const filteredData = dummyTransactionData.filter(account => 
                account.user_name.toLowerCase().includes(searchData.toLowerCase())
            );
            setAccountData(filteredData);
        }
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(dummyTransactionData.length / 5);
    const startIndex = (currentPage - 1) * 5;
    const endIndex = startIndex + 5;
    const currentData = dummyTransactionData.slice(startIndex, endIndex);

    const nextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(prevPage => prevPage - 1);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };

    const handleSaveChanges = () => {
        console.log("open");
    };

    return (
        <>
            <body>
                <Adminsidebar />
                <section className="home-section">
                    <AdminMobileSidebar />
                    {loader && 
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    }
                    
                    <div className="home-content">
                    <div className="overlay">
                <div className="banner-content px-5">
                    <div className="row justify-content-between align-items-center " style={{ marginTop: "-20px" }}>
                        <div className="col-lg-8 col-md-8 px-4">
                 
                        </div>
              
                     
                    </div>
                </div>
            </div>
                        <div className="sales-boxes">
                            <div className="recent-sales box">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="title">All transfers</div>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                                        <input 
                                            placeholder='Search by Name' 
                                            value={searchValue} 
                                            onChange={(e) => setSearchValue(e.target.value)} 
                                        />
                                        <button 
                                            className='"btn btn-success mx-2' 
                                            style={{ padding: '10px', border: '1px solid black', borderRadius: '10px' }} 
                                            onClick={fetchSearchAccounts}
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>
                                <table className="table table-striped" style={{ marginTop: '25px' }}>
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">User Name</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Transaction Date</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentData && currentData.map((transaction, index) => (
                                            <tr key={transaction.transaction_id}>
                                                <th scope="row">{startIndex + index + 1}</th>
                                                <td>{transaction.user_name}</td>
                                                <td>${transaction.amount.toFixed(2)}</td>
                                                <td>{formatDate(transaction.transaction_date)}</td>
                                                <td>{capitalizeWord(transaction.status)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="button">
                                    {currentPage > 1 && <button className="mx-2 btn btn-info" onClick={prevPage}>Previous</button>}
                                    {currentPage < totalPages && <button className="mx-2 btn btn-info" onClick={nextPage}>Next</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </body>
        </>
    );
}

export default UserTotalTranfer;
