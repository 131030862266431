import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// import { FaDownload, FaArrowsRotate } from "react-icons/fa";
import Header from '../Header';
import { getApi } from '../../service/apiService';
import { capitalizeWord, formatDate } from '../../service/utils';
import UserMobileSidebar from './UserMobileSidebar';

function UserTransectionById() {
  const location = useLocation();
  const [transfer, setTransfer] = useState(null);
  const [loader, setLoader] = useState(false);
  const transferId = location.state?.transfer_id; // Access the transfer_id from state
  // Find the matching transfer using filter
  // const matchedTransfer = getTransferDetails.find((detail) => detail.transfer?.id === transferId);

  // if (!matchedTransfer) {
  //   return <div>No matching transfer found</div>;
  // }

  // const transfer = matchedTransfer.transfer;

  useEffect(() => {
    if (transferId){
      fetchGetTotalTransfersById();
    }
  }, [transferId]);

  const fetchGetTotalTransfersById = async () => {
    setLoader(true);
    try {
      const data = await getApi(`client/transfer/${transferId}`);
      console.log('Data loaded successfully', data);
      setTransfer(data);
      setLoader(false);
    } catch (error) {
      console.error('Error occurred while fetching data', error);
      //   toast.error("Error occurred while fetching data");
      setLoader(false);
    }
  };

  return (
    <div>
      <section className="">
        {/* Loader */}
        <UserMobileSidebar />
        {loader && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
        <div className="overlay py-4 pb-120">
          <div className="container wow fadeInUp">
            {transfer ?
              <div className="row justify-content-center">
                <div className="col-lg-6 my-3 ">
                  {/* <div className="section-header mt-2  dark-sec text-center"> */}
                  <h3 className="text-5xl font-bold text-blue-900 text-center"> Transfer Report</h3>
                  {/* </div> */}
                </div>

                <div className='d-flex flex-row justify-content-between'>
                  <h5 className="mx-2" style={{ marginLeft: "20px" }}>Overview</h5>
                  {/* <div>
                  <button onClick={handleRefreshReport} className="btn px-2 me-2 btn-primary">
                    <FaArrowsRotate />
                    <span className="mx-2 text-white">Refresh</span>
                  </button>
                  <button onClick={getReportPDF} className="btn px-3 btn-primary">
                    <FaDownload />
                    <span className="mx-2 text-white">Pdf</span>
                  </button>
                  <button className="btn p-2 px-3 mx-2 btn-danger" onClick={deleteReport}>Delete</button>
                </div> */}
                </div>

                <div className="col-lg-12 row">
                  {/* Transfer Information */}
                  <div className="col-lg-6">
                    <p>Loan Information</p>
                    <div className="container">
                      <div className="row py-2 border-bottom">
                        <div className="col">Borrower Name</div>
                        <div className="col">{transfer?.loan?.name}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col">Loan Number</div>
                        <div className="col">{transfer?.loan?.number}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col">Property Address</div>
                        <div className="col">{transfer?.loan?.propertyAddress}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col">Total Loan Amount </div>
                        <div className="col">$ {transfer?.loan?.totalLoan}</div>
                      </div>
                    </div>
                  </div>

                  {/* User Information */}
                  <div className="col-lg-6">
                    <p>User Information</p>
                    <div className="container">
                      <div className="row py-2 border-bottom">
                        <div className="col">Name</div>
                        <div className="col">{transfer?.clientUser.first_name} {transfer?.clientUser.middle_name} {transfer?.clientUser.last_name}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col">Phone Number</div>
                        <div className="col">{transfer?.clientUser.phone_number}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col">Email</div>
                        <div className="col">{transfer?.clientUser.email}</div>
                      </div>
                      {/* <div className="row py-2 border-bottom">
                        <div className="col">Address</div>
                        <div className="col">{transfer?.clientUser.address.street}, {transfer?.user.address.city}, {transfer?.user.address.region}, {transfer?.user.address.postal_code}, {transfer?.user.address.country}</div>
                      </div> */}
                    </div>
                  </div>

                </div>

                {/* Transfer Information */}
                <div className="col-lg-12 row mt-4">
                  <p>Transfer Information</p>
                  <div className="col-lg-6">
                  
                    <div className="container">
                      <div className="row py-2 border-bottom">
                        <div className="col">Transfer ID</div>
                        <div className="col">{transferId}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col">Amount</div>
                        <div className="col">${transfer?.amount} {transfer?.iso_currency_code}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col">Status</div>
                        <div className="col">{capitalizeWord(transfer?.status)}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col">Last Activity</div>
                        <div className="col">{formatDate(transfer?.updatedAt)}</div>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-6">
                 
                    <div className="container">
                    <div className="row py-2 border-bottom">
                        <div className="col">Requested Date</div>
                        <div className="col">{formatDate(transfer?.requestedDate)}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col"> Due Date</div>
                        <div className="col">{formatDate(transfer?.dueDate)}</div>
                      </div>
                      <div className="row py-2 border-bottom">
                        <div className="col"> Paid Date</div>
                        <div className="col">{formatDate(transfer?.paidOn)}</div>
                      </div>
                    </div>
                  </div>
                  
                </div>

                {/* Transfer Table */}
                <div className="col-lg-12" style={{ marginTop: '25px' }}>
                  <p>Transaction Summary</p>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">ACH class</th>
                        <th scope="col">Network</th>
                        <th scope="col">Description</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{transfer?.TransferIntentResponse?.ach_class || "N/A"}</td>
                        <td>{transfer?.TransferIntentResponse?.network || "N/A"}</td>
                        <td>{transfer?.TransferIntentResponse?.description || "N/A"}</td>
                        <td>${transfer?.TransferIntentResponse?.amount || "N/A"}</td>
                        <td>{capitalizeWord(transfer?.TransferIntentResponse?.status || "N/A")}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
              :
              <div>
                {!loader &&
                  <div className="flex justify-center mt-24">
                    <h3 className="text-5xl font-bold text-blue-900 text-center">No Transfer Found</h3>
                  </div>
                  }
              </div>
            }
          </div>
        </div>
      </section>
    </div>
  );
}

export default UserTransectionById;
