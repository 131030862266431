// import React from 'react'

// function RegisterAdmin() {
//   return (
//     <div>
//          <section class="vh-100 pt-120" style={{backgroundColor:" #eee"}}>
//   <div class="container h-100">
//     <div class="row d-flex justify-content-center align-items-center h-100">
//       <div class="col-lg-12 col-xl-11">
//         <div class="card text-black" style={{borderRadius: "25px"}}>
//           <div class="card-body p-md-5">
//             <div class="row justify-content-center">
//               <div class="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">

//                 <p class="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Sign up</p>

//                 <form class="mx-1 mx-md-4">

//                   <div class="d-flex flex-row align-items-center mb-4">
//                     <i class="fas fa-user fa-lg me-3 fa-fw"></i>
//                     <div data-mdb-input-init class="form-outline flex-fill mb-0">
//                       <input type="text" id="form3Example1c" class="form-control" />
//                       <label class="form-label" for="form3Example1c">Your Name</label>
//                     </div>
//                   </div>

//                   <div class="d-flex flex-row align-items-center mb-4">
//                     <i class="fas fa-envelope fa-lg me-3 fa-fw"></i>
//                     <div data-mdb-input-init class="form-outline flex-fill mb-0">
//                       <input type="email" id="form3Example3c" class="form-control" />
//                       <label class="form-label" for="form3Example3c">Your Email</label>
//                     </div>
//                   </div>

//                   <div class="d-flex flex-row align-items-center mb-4">
//                     <i class="fas fa-lock fa-lg me-3 fa-fw"></i>
//                     <div data-mdb-input-init class="form-outline flex-fill mb-0">
//                       <input type="password" id="form3Example4c" class="form-control" />
//                       <label class="form-label" for="form3Example4c">Password</label>
//                     </div>
//                   </div>

//                   <div class="d-flex flex-row align-items-center mb-4">
//                     <i class="fas fa-key fa-lg me-3 fa-fw"></i>
//                     <div data-mdb-input-init class="form-outline flex-fill mb-0">
//                       <input type="password" id="form3Example4cd" class="form-control" />
//                       <label class="form-label" for="form3Example4cd">Repeat your password</label>
//                     </div>
//                   </div>

//                   <div class="form-check d-flex justify-content-center mb-5">
//                     <input class="form-check-input me-2" type="checkbox" value="" id="form2Example3c" />
//                     <label class="form-check-label" for="form2Example3">
//                       I agree all statements in <a href="#!">Terms of service</a>
//                     </label>
//                   </div>

//                   <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
//                     <button  type="button" data-mdb-button-init data-mdb-ripple-init class="btn btn-primary btn-lg">Register</button>
//                   </div>

//                 </form>

//               </div>
//               <div class="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">

//                 <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp"
//                   class="img-fluid" alt="Sample image" />

//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </section> *
//     </div>
//   )
// }

// export default RegisterAdmin


import React from 'react';
import AdminMobileSidebar from './AdminMobileSidebar';
import Adminsidebar from './AdminSidebar';
import Register from '../../Pages/Register';
function RegisterAdmin() {
  return (
    <body>
      <Adminsidebar />
      <section className="home-section">
        <AdminMobileSidebar />
        <section class="vh-100 pt-120" style={{backgroundColor:" #eee"}}>
  <div class="container h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-lg-12 col-xl-11">
        <div class="card text-black" style={{borderRadius: "25px"}}>
          <div class="card-body p-md-5">
            <div class="row justify-content-center">
              <div class="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">

                <p class="text-center h1 fw-bold mb-4 mx-1 mx-md-4 mt-4">Add new Admin</p>

                <form class="mx-1 mx-md-4">

                  <div class="d-flex flex-row align-items-center mb-4">
                    <i class="fas fa-user fa-lg me-3 fa-fw" style={{marginBottom:'30px'}}></i>
                    <div data-mdb-input-init class="form-outline flex-fill mb-0">
                      <input type="text" id="form3Example1c" class="form-control" />
                      <label class="form-label" for="form3Example1c">First Name</label>
                      
                    </div>
                  </div>
                  <div class="d-flex flex-row align-items-center mb-4">
                    <i class="fas fa-user fa-lg me-3 fa-fw" style={{marginBottom:'30px'}}></i>
                    <div data-mdb-input-init class="form-outline flex-fill mb-0">
                      <input type="text" id="form3Example1c" class="form-control" />
                      <label class="form-label" for="form3Example1c">Middle Name</label>
                      
                    </div>
                  </div>
                  <div class="d-flex flex-row align-items-center mb-4">
                    <i class="fas fa-user fa-lg me-3 fa-fw" style={{marginBottom:'30px'}}></i>
                    <div data-mdb-input-init class="form-outline flex-fill mb-0">
                      <input type="text" id="form3Example1c" class="form-control" />
                      <label class="form-label" for="form3Example1c">Last Name</label>
                      
                    </div>
                  </div>

                  <div class="d-flex flex-row align-items-center mb-4">
                    <i class="fas fa-envelope fa-lg me-3 fa-fw" style={{marginBottom:'30px'}}></i>
                    <div data-mdb-input-init class="form-outline flex-fill mb-0">
                      <input type="email" id="form3Example3c" class="form-control" />
                      <label class="form-label" for="form3Example3c"> Email</label>
                    </div>
                  </div>

                  <div class="d-flex flex-row align-items-center mb-4">
                    <i class="fas fa-lock fa-lg me-3 fa-fw" style={{marginBottom:'30px'}}></i>
                    <div data-mdb-input-init class="form-outline flex-fill mb-0">
                      <input type="password" id="form3Example4c" class="form-control" />
                      <label class="form-label" for="form3Example4c">Password</label>
                    </div>
                  </div>

                
                  {/* <div class="form-check d-flex justify-content-center mb-5">
                    <input class="form-check-input me-2" type="checkbox" value="" id="form2Example3c" />
                    <label class="form-check-label" for="form2Example3">
                      I agree all statements in <a href="#!">Terms of service</a>
                    </label>
                  </div> */}

                  <div class="d-flex justify-content-center mx-4 mb-1 mb-lg-4">
                    <button  type="button" data-mdb-button-init data-mdb-ripple-init class="btn btn-primary btn-lg"  style={{backgroundColor:"#0a2558"}}>Register</button>
                  </div>

                </form>

              </div>
              <div class="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">

                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp"
                  class="img-fluid" alt="Sample image" />

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> 
      </section>
    </body>
  );
}

export default RegisterAdmin;
