import React ,{useState , useEffect} from 'react'
import Adminsidebar from './AdminSidebar'
import AdminMobileSidebar from './AdminMobileSidebar'
import { getApi, postApi } from '../../service/apiService';


function UserNotification() {
    const [loader , setLoader] = useState(false);
    const [requestTransferData , setrequestTransferData] = useState([]);

    const AllTotalRequestdTransfers = async () => {
        setLoader(true);
        try {
          const data = await getApi('transfer/Requested/getAll');
          console.log('Requestd Transfers loaded successfully', data);
          setrequestTransferData(data);
          setLoader(false);
        } catch (error) {
          console.error('Error occurred while fetching data', error);
        //   toast.error("Error occurred while fetching data");
  
          setLoader(false);
        }
      };



  return (
    <div>
    <Adminsidebar />
    <section className="home-section">
        <AdminMobileSidebar />
        {loader && 
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        }
            <div className="sales-boxes">
                            <div className="recent-sales box">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="title">Total Users</div>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                                        <input placeholder='Search by Name or Email'  />
                                        <button className='"btn btn-success mx-2' style={{ padding:'10px', border:'1px solid black' , borderRadius:'10px'}} >Search</button>
                                    </div>
                                </div>
                               
                                <table className="table table-striped" style={{ marginTop: '25px' }}>
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">User Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Register Date</th>
                                            {/* <th scope="col">Generated Date</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {requestTransferData.map((account, index) => (
                                            <tr key={index}>
                                                <th scope="row">{  index + 1}</th>
                                                <td>{account.first_name} {account.middle_name} {account.last_name}</td>
                                                <td>{account.email}</td>
                                                <td>{account.days_requested}</td>
                                                {/* <td>{formatDate(account.date_generated)}</td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {/* <div className="button">
                                    {currentPage > 1 && <button className="mx-2 btn btn-info" onClick={prevPage}>Previous</button>}
                                    {currentPage < totalPages && <button className="mx-2 btn btn-info" onClick={nextPage}>Next</button>}
                                </div> */}
                            </div>
                        </div>
        
       
    </section>
</div>
  )
}

export default UserNotification