 export const formatDate = (dateString) => {
    if(!dateString) return "N/A"
    const date = new Date(dateString);

    const day = String(date.getUTCDate()).padStart(2, '0'); // Day in UTC
    const month = date.toLocaleString('default', { month: 'long', timeZone: 'UTC' }); // Month name in UTC
    const year = date.getUTCFullYear(); // Year in UTC
  
    return `${day} ${month} ${year}`;

  };


  export function capitalizeWord(word) {
    if(!word) return ""
    let trim =  word.trim();    
    return trim.charAt(0).toUpperCase() + trim.slice(1).toLowerCase();
}