import React, { useState, useEffect, useContext } from 'react';
import '../Admin/Admin.css';
import { RxDashboard } from 'react-icons/rx';
import { SiGoogleforms } from 'react-icons/si';
import { CiCircleList } from 'react-icons/ci';
import { IoIosSettings } from 'react-icons/io';
import { CiLogout } from 'react-icons/ci';
import { GrUserAdmin } from 'react-icons/gr';
import { Link, useNavigate } from 'react-router-dom';
import Hamburger from 'hamburger-react';
import { getApi } from '../../service/apiService';
import logo from '../../images/MCMHouse.png'
import { AuthContext } from '../../Context/AuthContext';


function UserMobileSidebar() {
  const { auth, login } = useContext(AuthContext);
  // const [id, setId] = useState(null);
  // const [data, setData] = useState({});
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/');
    localStorage.removeItem('userToken');;
    localStorage.removeItem('auth'); 
  };
  const handleGoToHome = () => {
    navigate('/user/dashboard');
  };

  // useEffect(() => {
  //   const token = localStorage.getItem('token');
  //   const userid = localStorage.getItem('userId');
  // }, []);

  // const getUserData = async () => {
  //   // console.log("api call")
  //   try {
  //     const response = await getApi(`client/client/${id}`);

  //     //  console.log("response ", response);
  //     setData(response);
  //     if (data) {
  //     }
  //   } catch (error) {
  //     console.log('please wait report is generating please try again some time later');
  //   }
  // };

  // useEffect(() => {
  //   if (id) {
  //     getUserData()
  //   }
  // }, [id])



  return (
    <>
      <nav class="d-flex px-5 py-3 justify-content-between" style={{ backgroundColor: '#6483bc' }}>
        <div class="sidebar-button" onClick={handleGoToHome}>
          <i class='bx bx-menu sidebarBtn'></i>
          <span class="dashboard">
            <img style={{ maxWidth: "9%" }} src={logo} />
          </span>
        </div>
        {/* <div class="mobileham" style={{marginRight:'179px'}}>
          <Hamburger toggled={isOpen} toggle={setOpen}  style={{innerWidth:'210px'}}/>
        </div>
        { isOpen &&
          <div className='mobileSidbar'>
            <div style={{position:"relative" , left: "9px" , color:'white'}} className='mobileham'>
              <Hamburger toggled={isOpen} toggle={setOpen}   />
            </div>
            <div className='mobileNavcontainer'>
              <ul class="nav-links">
                <li className='my-3' style={{color:'white'}}>
                  <Link to="/home" class="active" style={{color:'white'}}>
                    <RxDashboard  class='mx-3' style={{color:'white'}} />
                    <span class="links_name mx-3"  style={{color:'white'}}>Dashboard</span>
                  </Link>
                </li>
                <li className='my-3'  style={{color:'white'}}>
                  <Link to="/admin/all">
                    <SiGoogleforms class='mx-3'  style={{color:'white'}}/>
                    <span class="links_name mx-3"  style={{color:'white'}}>Total Users</span>
                  </Link>
                </li>
                <li className='my-3'    style={{color:'white'}}>
                  <Link to="/report/all">
                    <CiCircleList  
                      style={{color:'white'}}
                      class='mx-3'
                    />        
                    <span class="links_name mx-3"  style={{color:'white'}}>Total Reports</span>
                  </Link>
                </li>
                <li className='my-3'    style={{color:'white'}}>
                  <Link to="/report/accounts">
                    <CiCircleList  
                      style={{color:'white'}}
                      class='mx-3'
                    />        
                    <span class="links_name mx-3"  style={{color:'white'}}> Linked Accounts</span>
                  </Link>
                </li>
                <li className='my-3' style={{color:'white'}}>
                  <Link to="/settings">
                    <IoIosSettings 
                      class='mx-3'
                      style={{color:'white'}}
                    />
                    <span class="links_name mx-3"  style={{color:'white'}}>Setting</span>
                  </Link>
                </li>
                <li className='my-3' style={{color:'white'}}>
                  <a onClick={handleLogout}>
                    <CiLogout  
                      class='mx-3 text-white'
                    />
                    <span class="links_name mx-3"  style={{color:'white'}}>Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        } */}
        <div class="profile-details">

          <div class="dropdown">
            <div class="btn  dropdown-toggle" type="button" id="dropdownNavarMenu" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="https://www.shutterstock.com/image-vector/blank-avatar-photo-place-holder-600nw-1114445501.jpg" alt="" />
              <span class="admin_name" style={{ color: 'black' }}> {auth?.user && auth?.user?.first_name} {auth?.user && auth?.user?.last_name} </span>

            </div>
            <ul class="dropdown-menu" style={{zIndex: 9999 }} aria-labelledby="dropdownNavarMenu">
              <li>
                <Link to="/user/transaction" className={'dropdown-item'}>
                  <SiGoogleforms class='me-2' style={{ color: '#7370ca' }} />
                  <span class="links_name mx-2">Total Transaction</span>
                </Link>
              </li>
              <li>
                <Link to="/user/setting" className={ 'dropdown-item'}>
                  <IoIosSettings style={{ color: '#7370ca' }} class='me-2' />
                  <span class="links_name mx-2">Setting</span>
                </Link>
              </li>
              <li>
                <a onClick={handleLogout}  className={'dropdown-item'} >
                  <CiLogout style={{ color: '#7370ca' }} class='me-2' />
                  <span class="links_name mx-2">Logout</span>
                </a>
              </li>
            </ul>
          </div>



        </div>
      </nav>
    </>
  );
}

export default UserMobileSidebar;
