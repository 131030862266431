import React from "react"

const DisplayCard = ({ title , subtitle="" ,  value  ,  })=>{

    return (
     <div className={`spending-card mb-2 gap-4 rounded-xl shadow-2xl overflow-hidden  `}>
     <div className="spending-info text-blue-900">
       <div className="spending-title">{title}</div>
       <div className="">{subtitle}</div>
     </div>
     <div
       className="progress-circle"
       style={{ "--progress": "100%" }}
     >
       <span>{value}</span>
     </div>
     </div>
    )
 } 


export default DisplayCard;