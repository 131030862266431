import './App.css';
import { AuthProvider } from './Context/AuthContext';
import PublicRoutes from './Routes/PublicRoutes';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <>
      <AuthProvider>
        <PublicRoutes />
        <ToastContainer />
      </AuthProvider>
    </>
  );
}

export default App;
