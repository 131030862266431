import React, { useState, useEffect } from "react";
import {
  FiDollarSign,
  FiUser,
  FiChevronDown,
  FiFileMinus,
} from "react-icons/fi";
// import { BsBank2 } from "react-icons/bs";
import { formatDate } from "../../service/utils";

const PaymentPage = ({ onSubmit, user, loanRequest = [] }) => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isShowLoanList, setIsShowLoanList] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [errors, setErrors] = useState({});
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const todayDate = new Date();

  let defaultOption =   {
    "id": null,
    "name": user.account ?  "Other Account" : "Link Account",
    "mask": user.account ?  "Oth" : "Link", 
    "type": user.account ?  "Oth" : "Link", 
    "subtype": user.account ?  "Oth" : "Link", 
    "verification_status": null,
    "class_type": null
  }

  const validateForm = () => {
    const newErrors = {};
    if (!selectedAccount || selectedAccount === null)
      newErrors.selectedAccount = "Please select an account";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat().format(num);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit({ account: selectedAccount, loan: selectedLoan , type : selectedAccount.id ? "paid" :"update" });
      console.log("Form selectedAccount:", selectedAccount);
    }
  };

  const handleLoanSelect = (loan)=>{
    setSelectedLoan(loan);
    setIsShowLoanList(false);
  }

  const DetailsRow = ({ title = "", value = "" }) => {
    return (
      <div className="row py-2 border-bottom">
        <div className=" col-4">
          <span className="text-muted">{title} </span>
        </div>
        :
        <div className=" col-7 text-wrap  ">
          <span className="h5 w-100 fw-semibold text-center text-break text-primary-dark">
            {value}
          </span>
        </div>
      </div>
    );
  };

  useEffect(()=>{
    // console.log("loanRequest set " ,loanRequest )


    if(loanRequest && loanRequest.length ===1){
      setSelectedLoan(loanRequest[0]);
    }else{
      setSelectedLoan(null);
    }
    setIsShowLoanList(false);
    if(!user.account){
      setSelectedAccount(defaultOption);
    }
  },[loanRequest])
  //  {/* <div className="min-h-screen bg-gradient-to-br from-blue-900 to-blue-800 py-12 px-4 sm:px-6 lg:px-8"> */}
  return (
    <div className="mx-4  md:mx-1">
      <div className=" px-3 py-2 mb-2 d-flex  flex-row justify-content-between  bg-white rounded-3 shadow ">
        <h4 className="display-5 text-center mt-2 fs-3 fw-bold text-blue-80">
          {selectedLoan ?  <>Welcome {selectedLoan?.loan.name} !</> :  "Loan Requests "}
           
        </h4>
        <div className="position-relative" style={{ width: "33%" }}>
          <div
            className="btn btn-light w-100 text-left btn-lg dropdown-toggle text-truncate"
            style={{ color: "#0c266c" }}
            onClick={() => setIsShowLoanList(!isShowLoanList)} // Toggle dropdown on click
            aria-expanded={isShowLoanList}
            aria-haspopup="listbox"
          >
            {selectedLoan ? `${selectedLoan.loan.number}` : "Select Loan"}
          </div>
          {isShowLoanList && (
            <ul className="position-absolute list-group overflow-scroll  bg-white my-2 w-100" style={{"zIndex" : 2 , maxHeight : "300px"}}>
              {loanRequest.map((loan, index) => (
                <li
                  key={loan._id}
                  className="px-3 hover:bg-blue-50 cursor-pointer list-group-item"
                  onClick={() =>handleLoanSelect(loan) }
                  role="option"
                >
                  {loan.loan.number}  {"( "} {loan.loan.propertyAddress}{" )"}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {selectedLoan && (
        <div className="p-4 bg-white rounded-3 shadow overflow-hidden">
          <h4 className=" mb-3  fw-bold text-primary-dark">
            Mortgage Loan Payment Request by  {selectedLoan?.loan.lenderName}
          </h4>
          <div class="row gap-4">
            <div class="col ">
              {/* <DetailsRow
                title="User Name"
                value={`${user?.first_name || "N/A"} ${user?.last_name} `}
              /> */}
              <DetailsRow
                title="Borrower Name"
                value={selectedLoan?.loan.name}
              />
              <DetailsRow
                title="Loan Number"
                value={selectedLoan?.loan.number}
              />
              <DetailsRow
                title="Total Loan Amount"
                value={`$ ${selectedLoan?.loan?.totalLoan}`}
              />
              <DetailsRow
                title="Property Address"
                value={selectedLoan?.loan.propertyAddress}
              />
              <DetailsRow
                title="Requested Date"
                value={`${formatDate(
                  selectedLoan?.requestedDate
                )} `}
              />
              <DetailsRow
                title="Payment Due Date"
                value={`${formatDate(selectedLoan?.dueDate)} `}
              />
            </div>
            <div class="col ">
              <div className=" pt-2 row justify-content-center">
                <span className="h5 fw-semibold text-primary-dark text-center">
                  {" "}
                  Monthly Payment Amount
                </span>
              </div>
              <div className=" row py-2 justify-content-center ">
                <div
                  className="progress-circle"
                  style={{ "--progress": "100%" }}
                >
                  <span>
                    ${formatNumber(selectedLoan?.amount)}{" "}
                  </span>
                </div>
              </div>
              <div className="relative">
                <label
                  htmlFor="account"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  <span className="h5 fw-semibold text-primary-dark">
                    Select Account:
                  </span>
                </label>
                <div
                  className={`relative border ${
                    errors.selectedAccount
                      ? "border-red-500"
                      : "border-gray-300"
                  } dropdown rounded-3 cursor-pointer`}
                 
                >
                  <div
                    role="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className="d-flex  btn dropdown-toggle items-center justify-content-between  px-3 py-2"
                  >
                    <span className="h5 fw-normal m-0 p-0 text-primary-dark">
                      {selectedAccount?.name
                        ? `${selectedAccount.name} - ${selectedAccount.mask}`
                        : "Choose an account"}
                    </span>
                  </div>
                  <ul
                    className="dropdown-menu w-100  bg-white border border-gray-200 rounded-2 mt-1 shadow"
                    role="listbox"
                  >
                    {user?.account?.accounts?.length > 0 && user?.account?.accounts?.map((account, index) => (
                      <li
                        key={account.id}
                        className="px-2 hover:bg-blue-50 cursor-pointer"
                        onClick={() => {
                          setSelectedAccount(account);
                        }}
                        role="option"
                        aria-selected={selectedAccount?.id === account?.id}
                      >
                        {account.name} - {account.mask}
                      </li>
                    ))}
                   <li
                      key="other"
                      className="px-2 hover:bg-blue-50 cursor-pointer"
                      onClick={() => {
                        setSelectedAccount(defaultOption);
                      }}
                      role="option"
                      aria-selected={selectedAccount?.id === null}
                    >
                        {defaultOption.name} - {defaultOption.mask}
                    </li>
                  </ul>
                </div>
              </div>

              {selectedAccount && selectedAccount !== null && (
                <div className="bg-light mt-3 px-3 py-2 rounded space-y-2">
                  <div className="">
                    <span className="h5 fw-semibold text-primary-dark">
                      Payment Details:
                    </span>
                  </div>
                  {/* <div className="d-flex justify-content-between">
                      <span className="text-muted">Amount:</span>
                      <span className="fw-semibold">
                        ${formatNumber(selectedLoan?.amount)}
                      </span>
                    </div> */}
                  <div className="d-flex justify-content-between">
                    <span className="text-muted">Account:</span>
                    <span className="fw-semibold">{selectedAccount.name}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="text-muted">Number:</span>
                    <span className="fw-semibold">
                      {" "}
                      **** **** **** {selectedAccount.mask}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <span className="text-muted">Type :</span>
                      <span className="ml-1 fw-semibold">
                        &#20;{selectedAccount.type}
                      </span>
                    </div>
                    <div>
                      <span className="text-muted">Subtype :</span>
                      <span className="ml-1 fw-semibold">
                        &#20;{selectedAccount.subtype}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <form onSubmit={handleSubmit} className=" mt-3">
            <button
              disabled={selectedAccount === null && user?.account}
              type="submit"
              className="w-100 my-3 btn btn-primary btn-lg  rounded shadow-sm transition-transform duration-200 transform scale-hover disabled-opacity disabled-cursor-not-allowed"
            >
              Process Payment {!user.account && "And Link Account"}
            </button>

            <div className="mt-1 pb-1 small text-muted px-3 bg-light rounded">
              <h5 className="fw-medium">By clicking Process Payment:</h5>
              <ul className="list-unstyled mt-1 ps-3 ">
                <li className="small" style={{ lineHeight: "normal" }}>
                  You authorize a one-time ACH payment from your bank account.
                  and permit {selectedLoan?.loan.lenderName} to debit your account today on{" "}
                  <strong>{formatDate(todayDate)}</strong>. {selectedLoan?.loan.lenderName} has
                  obtained authorization to withdraw funds from your account.
                  this is a one-time, non-revocable debit.
                </li>
              </ul>
            </div>
          </form>

          {/* </div>
          ) : ( 
            <FontAwesomeIcon icon={faFileExcel} />
            <div className=" h-full">
              <div className="p-4 ">
                <FiFileMinus
                  color="#3b82f6"
                  className={`h-24 mt-24 mb-6 w-24 mx-auto `}
                />
                <h2 className="text-3xl align-middle font-bold text-blue-900 text-center">
                  No Loan Payment Request <br /> by MCM Holdings Inc.
                </h2>
              </div>
            </div>
          )} */}
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
