import React, { useState, useEffect } from "react";
import "../Admin/Admin.css";
import UserSidebar from "./UserSidebar";
import { useNavigate } from "react-router-dom";
import UserMobileSidebar from "./UserMobileSidebar";
import { getApi, putApi } from "../../service/apiService"; // Make sure these functions exist

function UserSetting() {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [id, setId] = useState();
  const [data, setData] = useState({});
  const [formValues, setFormValues] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    phone_number: '',
    email: '',
  });
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [resetToken, setResetToken] = useState(""); // Add a state to store the reset token

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userid = localStorage.getItem("userId");
    setId(userid);
    setResetToken(token);
  }, []);

  useEffect(() => {
    if (id) {
      getUserData();
    }
  }, [id]);

  const getUserData = async () => {
    setLoader(true);
    try {
      const response = await getApi(`client/client/${id}`);
      setData(response);
      setFormValues({
        first_name: response.first_name || '',
        middle_name: response.middle_name || '',
        last_name: response.last_name || '',
        phone_number: response.phone_number || '',
        email: response.email || '',
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const updateUser = async (e) => {
    e.preventDefault();
    try {
      const response = await putApi(`client/update/${id}`, formValues);
      getUserData();
      return response;
    } catch (error) {
      console.error("Error updating user:", error);
      throw error; // Optionally rethrow to handle it in the calling function
    }
  };

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();
    
    // Check if new password and confirm password match
    // if (passwords.newPassword !== passwords.confirmPassword) {
    //   alert("New password and confirm password do not match.");
    //   return;
    // }
    
    // // Check if current password and new password are not empty
    // if (!passwords.currentPassword || !passwords.newPassword) {
    //   alert("Both current password and new password are required.");
    //   return;
    // }
    
    setLoader(true);
    try {
      // Prepare the payload to send
      const payload = {
        currentPassword: passwords.currentPassword,
        newPassword: passwords.newPassword,
        token: resetToken // Include the reset token
      };
      
      const response = await putApi(`client/resetPassword`, payload);
      
      console.log("Password updated successfully:", response);
      
      // Optionally, refresh user data
      getUserData();
    } catch (error) {
      console.error("Error updating password:", error);
      alert("Error updating password. Please try again.");
    } finally {
      setLoader(false);
    }
  };

  useEffect(()=>{
    const token = localStorage.getItem('token');
    const userId =   localStorage.getItem("userId")
    
    if(!token && !userId){
     navigate('/user/login')
    }
 
   },[])

  return (
    <div className="admin-container">
      {/* <UserSidebar /> */}
      <section className="">
        <UserMobileSidebar />
        {loader && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
        <section className="bg-light py-3 py-md-5 py-xl-8" >
          <div className="container">
            <div className="row gy-4 gy-lg-0">
              <div className="col-12 col-lg-8 col-xl-12">
                <div className="card widget-card border-light shadow-sm">
                  <div className="card-body p-4">
                    <ul className="nav nav-tabs" id="profileTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="overview-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#overview-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="overview-tab-pane"
                          aria-selected="true"
                        >
                          Overview
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="profile-tab-pane"
                          aria-selected="false"
                        >
                          Edit Profile
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="password-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#password-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="password-tab-pane"
                          aria-selected="false"
                        >
                          Password
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content pt-4" id="profileTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="overview-tab-pane"
                        role="tabpanel"
                        aria-labelledby="overview-tab"
                        tabIndex="0"
                      >
                        <h5 className="mb-3">Profile</h5>
                        <div className="row g-0">
                          <div className="col-5 col-md-3 bg-light border-bottom border-white border-3">
                            <div className="p-2">First Name</div>
                          </div>
                          <div className="col-7 col-md-9 bg-light border-start border-bottom border-white border-3">
                            <div className="p-2">{data?.first_name}</div>
                          </div>
                          <div className="col-5 col-md-3 bg-light border-bottom border-white border-3">
                            <div className="p-2">Middle Name</div>
                          </div>
                          <div className="col-7 col-md-9 bg-light border-start border-bottom border-white border-3">
                            <div className="p-2">{data?.middle_name}</div>
                          </div>
                          <div className="col-5 col-md-3 bg-light border-bottom border-white border-3">
                            <div className="p-2">Last Name</div>
                          </div>
                          <div className="col-7 col-md-9 bg-light border-start border-bottom border-white border-3">
                            <div className="p-2">{data?.last_name}</div>
                          </div>
                          <div className="col-5 col-md-3 bg-light border-bottom border-white border-3">
                            <div className="p-2">Phone</div>
                          </div>
                          <div className="col-7 col-md-9 bg-light border-start border-bottom border-white border-3">
                            <div className="p-2">{data?.phone_number}</div>
                          </div>
                          <div className="col-5 col-md-3 bg-light border-bottom border-white border-3">
                            <div className="p-2">Email</div>
                          </div>
                          <div className="col-7 col-md-9 bg-light border-start border-bottom border-white border-3">
                            <div className="p-2">{data?.email}</div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="profile-tab-pane"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                        tabIndex="0"
                      >
                        <form onSubmit={updateUser} className="row gy-3 gy-xxl-4">
                          <div className="col-12 col-md-6">
                            <label htmlFor="inputFirstName" className="form-label">First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputFirstName"
                              name="first_name"
                              value={formValues.first_name}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label htmlFor="inputMiddleName" className="form-label">Middle Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputMiddleName"
                              name="middle_name"
                              value={formValues.middle_name}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label htmlFor="inputLastName" className="form-label">Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputLastName"
                              name="last_name"
                              value={formValues.last_name}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label htmlFor="inputPhone" className="form-label">Phone</label>
                            <input
                              type="tel"
                              className="form-control"
                              id="inputPhone"
                              name="phone_number"
                              value={formValues.phone_number}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label htmlFor="inputEmail" className="form-label">Email</label>
                            <input
                              type="email"
                              className="form-control"
                              id="inputEmail"
                              name="email"
                              value={formValues.email}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-12">
                            <button type="submit" className="btn btn-primary">Save Changes</button>
                          </div>
                        </form>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="password-tab-pane"
                        role="tabpanel"
                        aria-labelledby="password-tab"
                        tabIndex="0"
                      >
                        <form onSubmit={handlePasswordUpdate}>
                          <div className="row gy-3 gy-xxl-4">
                            <div className="col-12">
                              <label htmlFor="currentPassword" className="form-label">Current Password</label>
                              <input
                                type="password"
                                className="form-control"
                                id="currentPassword"
                                name="currentPassword"
                                value={passwords.currentPassword}
                                onChange={handlePasswordChange}
                              />
                            </div>
                            <div className="col-12">
                              <label htmlFor="newPassword" className="form-label">New Password</label>
                              <input
                                type="password"
                                className="form-control"
                                id="newPassword"
                                name="newPassword"
                                value={passwords.newPassword}
                                onChange={handlePasswordChange}
                              />
                            </div>
                            <div className="col-12">
                              <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                              <input
                                type="password"
                                className="form-control"
                                id="confirmPassword"
                                name="confirmPassword"
                                value={passwords.confirmPassword}
                                onChange={handlePasswordChange}
                              />
                            </div>
                            <div className="col-12">
                              <button type="submit" className="btn btn-primary">Change Password</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}

export default UserSetting;
