import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getApi } from '../service/apiService';
import logoImage from '../images/MCMHouse.png'

function Header( {show = false , home ='/' }) {

    const navigate = useNavigate();

    const handelGoToHome = () => {
        navigate(home);
    }

    const handleLogin = () => {
        // console.log("login")
        navigate('/user/login')
    }

    useEffect(() => {
  
    }, [])

    // const getUserData = async () => {
    //     console.log("api call")
    //     try {
    //       const response = await getApi(`users/user/${id}`);

    //        console.log("response ", response);
    //       if (data) {
    //       }
    //     } catch (error) {
    //      console.log('please wait report is generating please try again some time later');
    //     }
    //   };

    //   useEffect(()=>{
    //     getUserData()
    //   },[id])


    return (

        <header className="header-section" style={{ backgroundColor: '#6483bc' }}>
            <div className="container">
                <div className="row d-flex header-area">
                    <nav className="navbar  navbar-light" >
                        <img onClick={handelGoToHome} src={logoImage} className="logo customeLogog" alt="logo" />

                        {show &&
                           <div onClick={handleLogin} className="cmn-btn">Sign In
                           <i className="icon-d-right-arrow-2"></i>
                       </div>                        }
                    </nav>
                </div>
            </div>
        </header>

    )
}

export default Header