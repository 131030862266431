import React, { useContext } from 'react';
import { Routes, Route, Link, Outlet , Navigate } from 'react-router-dom';
import {  } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';

const ProtectedRoute = ({ role = null ,  type="admin" }) => {
  const { auth ,adminAuth, loading } = useContext(AuthContext);

  if (loading) {
    return  (<div className="loader-container">
    <div className="loader"></div>
  </div>)
  }

  if(type ==="admin"){
    if (!adminAuth.isAuthenticated) {
      return <Navigate to={`/login`} />;
    }
  
    if (role&&!role.includes(adminAuth.role)) {
      return <Navigate to="/404" />;
    }
  }else {
    if (!auth.isAuthenticated) {
      return <Navigate to={`/user/login`} />;
    }
  
    if (role&&!role.includes(auth.role)) {
      return <Navigate to="/404" />;
    }
  }
  


  return (
    <>
      <Outlet />
    </>
  );
};

export default ProtectedRoute;
