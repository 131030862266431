import React, { useState, useEffect, useContext } from 'react';
import './AdminAccount.css';
import { RxDashboard } from 'react-icons/rx';
import { SiGoogleforms } from 'react-icons/si';
import { CiCircleList } from 'react-icons/ci';
import { IoIosSettings } from 'react-icons/io';
import { CiLogout } from 'react-icons/ci';
import { GrUserAdmin } from 'react-icons/gr';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Hamburger from 'hamburger-react';
import { AuthContext } from '../../Context/AuthContext';

function Adminsidebar() {
  // const [isOpen, setOpen] = useState(false);
  const { adminAuth, } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    navigate('/login');
    window.localStorage.clear();
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      // navigate('/404'); // Navigate to 404 page if token is not present
    }
  }, []);

  return (
    <div class="sidebar">
      <div class="logo-details">
        <i class='bx bxl-c-plus-plus'></i>
        <GrUserAdmin style={{ color: 'white' }} />
        <span class="logo_name mx-3" style={{ fontSize: '15px' }}>Admin</span>
      </div>
      <ul class="nav-links">
        <li>
          <Link to="/account/dashboard" className={location.pathname === '/account/dashboard' ? 'active' : ''}>
            <RxDashboard class='mx-3' style={{ color: 'white' }} />
            <span class="links_name mx-3">Dashboard</span>
          </Link>
        </li>
        <li>
          <Link to="/account/transactions" className={location.pathname === '/account/transactions' ? 'active' : ''}>
            <SiGoogleforms class='mx-3' style={{ color: 'white' }} />
            <span class="links_name mx-3">Total Transaction</span>
          </Link>
        </li>
        <li>
          <Link to="/account/requests" className={location.pathname === '/account/requests' ? 'active' : ''}>
            <IoIosSettings style={{ color: 'white' }} class='mx-3' />
            <span class="links_name mx-3">Request Transaction</span>
          </Link>
        </li>
        <li>
          <Link to="/account/accounts" className={location.pathname === '/account/accounts' ? 'active' : ''}>
            <CiCircleList style={{ color: 'white' }} class='mx-3' />
            <span class="links_name mx-3">Total Accounts</span>
          </Link>
        </li>
        {(adminAuth.role == "admin") &&
          <li>
            <Link to="/admin/setting" className={location.pathname === '/admin/setting' ? 'active' : ''}>
              <IoIosSettings style={{ color: 'white' }} class='mx-3' />
              <span class="links_name mx-3">Administration settings</span>
            </Link>
          </li>
        }
        <li>
          <a onClick={handleLogout} style={{ color: 'white', cursor: 'pointer' }}>
            <CiLogout style={{ color: 'white' }} class='mx-3' />
            <span class="links_name mx-3">Logout</span>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Adminsidebar;
