import React, { useContext, useEffect, useState } from 'react';
import { MdAccountBalance } from "react-icons/md";
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import { getApi, postApi } from '../../service/apiService';
import Header from '../Header';
import { capitalizeWord, formatDate } from '../../service/utils';
import { AuthContext } from '../../Context/AuthContext';
let allData = [];
const CreateTransferRequest = () => {

    const [accountData, setAccountData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [showDialog, setShowDialog] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [lastFiveTransfer, setLastFiveTransfer] = useState([]);

    const { login } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        loanName: "",
        loanNumber: "",
        amount: "",
        dueDate: "",
        totalLoan: "",
        propertyAddress: "",
        lenderName:""
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const token = localStorage.getItem('token');
    const { userId } = useParams();

    const dummyTransactionData = [
        { transaction_id: 1, user_name: 'John Doe', amount: 120.50, transaction_date: '2024-09-01', status: 'Successful' },
        { transaction_id: 2, user_name: 'Jane Smith', amount: 89.99, transaction_date: '2024-08-30', status: 'Failed' },
        { transaction_id: 3, user_name: 'Bob Johnson', amount: 45.75, transaction_date: '2024-08-29', status: 'Pending' },
        { transaction_id: 4, user_name: 'Alice Davis', amount: 200.00, transaction_date: '2024-08-25', status: 'Successful' },
        { transaction_id: 5, user_name: 'Michael Brown', amount: 150.25, transaction_date: '2024-08-20', status: 'Successful' }
    ];

    const fetchSearchAccounts = () => {
        const searchData = searchValue.trim().toLowerCase();
        if (searchData) {
            const filteredData = allData.filter(account =>
                account?.loan?.name.toLowerCase().includes(searchData) ||
                account?.loan?.propertyAddress.toLowerCase().includes(searchData) ||
                account?.status.toLowerCase().includes(searchData) ||
                account?._id.toLowerCase().includes(searchData) ||
                account?.loan?.number.toLowerCase().includes(searchData)
            );
            console.log("Search " ,filteredData )
            setAccountData(filteredData);
        } else {
            GetAllTransferByUser()
        }
        setCurrentPage(1);
    };

    const fetchUserAccountData = async (id) => {
        setLoading(true)
        try {
            const response = await getApi(`client/details/${id}`);
            console.log("fetchUserAccountData response", response)
            setUserDetails(response);
         
            //   setData(response);
        } catch (error) {
            console.log('please wait report is generating please try again some time later');
        } finally {
            setLoading(false)
        }
    };

    const totalPages = (accountData.length == 10) ? currentPage + 1 : currentPage;
    const startIndex = (currentPage - 1) * 10;

    const handleSubmitTransferRequest = async (event) => {
        try {
            event.preventDefault();
            if (isDueDateValid(formData.dueDate)) {
            } else {
                toast.error("Date must be in the future");
                return
            }
            setLoading(true);
            let requestBody = {
                amount: formData.amount,
                clientUser: userId,
                dueDate: formData.dueDate,
                loan: {
                    name: formData?.loanName || "N/A",
                    number: formData?.loanNumber || "N/A",
                    totalLoan: formData?.totalLoan || 0,
                    propertyAddress: formData?.propertyAddress || "N/A",
                    lenderName:formData?.lenderName || "N/A",
                }
            }
            const response = await postApi('transfer/createRequest', requestBody);

            if (response.status === 200) {
                setLoading(false);
                toast.success('Transfer Request Created');
                setFormData({
                    loanName: "",
                    loanNumber: "",
                    amount: "",
                    dueDate: "",
                    totalLoan: "",
                    propertyAddress: "",
                    lenderName:""
                })
            } else {
                toast('Please enter a correct email and password');
                setLoading(false);
            }
        } catch (error) {
            let errorInfo = error.errorDetails
            console.error('Error occurred while creating request in', errorInfo.message);
            // setError('Something went wrong, please try again');
            toast.error( errorInfo?.message || 'Something went wrong, please try again');
            setLoading(false);
        } finally {
            // event.preventDefault(); 
        }
    };

    const handleLoginAsUser = async () => {
        setLoading(true)
        try {
            const { data } = await postApi(`client/loginAsUser`, { id: userId });
            console.log("handleLoginAsUser ", data.data)
            const dataObject = data.data

            if (dataObject) {
                localStorage.setItem('userToken', dataObject.token);
                localStorage.setItem('userId', dataObject._id);
                localStorage.setItem('type', "user");
                //  console.log(response.data);
                login({ user: dataObject, role: dataObject.role, token: dataObject.token });
                // toast.success("Login successful!");
                setLoading(false);
                navigate('/user/dashboard');
            }

        } catch (error) {
            console.log('please wait report is generating please try again some time later');
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        if (userId) {
            fetchUserAccountData(userId);
        }

    }, [])

    const handleInput = (event) => {
        setFormData({ ...formData, [event.target.id]: event.target.value })
        // setSelectedDays(event.target.value); // Update the selected value state
    };

    function checkDate(event) {
        if (isDueDateValid(event.target.value)) {
            setFormData({ ...formData, dueDate: event.target.value })
        } else {
            toast.error("Date must be in the future");
        }
    }

    function isDueDateValid(date) {
        if (!date) return false
        let now = new Date();
        let selectedDate = new Date(date);
        return (now < selectedDate)
    }

    const deleteAccounts = async () => {
        try {
            const { data } = await postApi('client/delete', { userId: userId });
            if (data.success) {
                toast.info("Account Delete Successfully ");
                handleCancel()
                setTimeout(() => {
                    navigate('/account/dashboard');
                }, [2000])

            } else {
                toast.error("Unable to delete Account");
            }

        } catch (error) {
            console.error('Error occurred while fetching data', error);
            toast.error(error.message || "Internal Server Error");

        }
    };

    const nextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(prevPage => prevPage - 1);
    };

    const GetAllTransferByUser = async () => {
        try {
            const data = await getApi(`transfer/getByUser/${userId}`);
            allData= data
            setAccountData(data)
        } catch (error) {
            // toast("Internal server Error please try again some time later");
            // console.log("error", error);
        }
    };

    useEffect(() => {
        GetAllTransferByUser()
    }, [currentPage])

    const getTransferByID = (report) => {
        // setLoader(true);
        navigate(`/account/transaction/${report._id}`, { state: { transfer_id: report._id } });
    };


    // console.log("lastLoginTime" ,userDetails);
    const handleDelete = () => { setShowDialog(true); };

    const handleCancel = () => { setShowDialog(false); };

    return (
        <div>
            <Header home="/account/dashboard" />
            <section className="banner-section index">
                {loading && (
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                )}
                <div>
                    <div className="overlay">
                        <div className="banner-content">
                            {userDetails ?
                                <div className=" mx-5 wow fadeInUp">
                                    <div className="row  justify-content-center" style={{ marginTop: -38 }}>
                                        <h3 className="fw-bold text-center">Create {" "}
                                            <span className="fw-bold" style={{
                                                fontSize: "43px",
                                                lineHeight: "55.9px",
                                                color: '#00be90e8'
                                            }}>
                                                {userDetails?.account?.institution?.name}
                                            </span>   Transfer Request</h3>
                                    </div>
                                    <div className="row my-4 justify-content-between ">
                                        <div className="col-md-5 col-12 mt-4">
                                            <div className="d-flex justify-content-between ">
                                                <div className="left-side">
                                                    <span className="xlr fw-bold">User Information</span>
                                                </div>
                                                <button className="btn  px-3 mx-2 btn-danger" onClick={handleDelete}>Delete</button>
                                            </div>
                                            <div className="container">
                                                <div className="row  py-2 border-bottom ">
                                                    <div className="col">First Name</div>
                                                    <div className="col">{userDetails?.first_name}</div>
                                                </div>
                                                <div className="row py-2 border-bottom">
                                                    <div className="col">Middle Name</div>
                                                    <div className="col">{userDetails?.middle_name}</div>
                                                </div>
                                                <div className="row py-2 border-bottom">
                                                    <div className="col">Last Name</div>
                                                    <div className="col">{userDetails?.last_name}</div>
                                                </div>
                                                <div className="row py-2 border-bottom">
                                                    <div className="col">Email </div>
                                                    <div className="col">{userDetails?.email}</div>
                                                </div>
                                                <div className="row py-2 border-bottom">
                                                    <div className="col">Transfer Count </div>
                                                    <div className="col">{userDetails?.transferCount}</div>
                                                </div>
                                                <div className="row py-2 border-bottom">
                                                    <div className="col">No of Accounts</div>
                                                    <div className="col">{userDetails?.account?.accounts?.length || "Account is not linked"}</div>
                                                </div>
                                                <div className="row py-2 border-bottom">
                                                    <div className="col">last logged in </div>
                                                    <div className="col">{formatDate(userDetails.lastLoginTime)}</div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end mt-3 ">
                                                <button className="ml-auto py-2 px-3 btn btn-primary my-4" onClick={handleLoginAsUser} >Login in user account </button>
                                            </div>
                                        </div>
                                        <div className="col-md-7 col-12">
                                            <div className="send-money d-flex flex-column justify-content-between" style={{ minHeight: "400px" }}>

                                                <div >
                                                    <div className="pb-2 d-flex justify-content-between ">
                                                        <div className="left-side">
                                                            <span className="xlr fw-bold"> Loan Details</span>
                                                        </div>
                                                        {/* <button className="btn  px-3 mx-2 btn-danger" onClick={() => { deleteAccounts() }}>Delete</button> */}
                                                    </div>
                                                    <form className="row g-3" onSubmit={handleSubmitTransferRequest}>
                                                      <div className="col-md-12">
                                                            <label htmlFor="lenderName" className="form-label">Lender Name</label>
                                                            <input type="text" className="form-control" value={formData?.lenderName} onChange={handleInput} id="lenderName" placeholder="Lender Name" />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label htmlFor="loanName" className="form-label">Name</label>
                                                            <input type="text" className="form-control" value={formData?.loanName} onChange={handleInput} id="loanName" placeholder="Borrower Name" />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label htmlFor="loanNumber" className="form-label">Number*</label>
                                                            <input required type="text" className="form-control" value={formData?.loanNumber} onChange={handleInput} id="loanNumber" placeholder="Loan Number" />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label htmlFor="amount" className="form-label">Request Amount*</label>
                                                            <input required type="number" step="0.01" min={1} className="form-control" value={formData?.amount} onChange={handleInput} id="amount" placeholder="Request Amount" />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label htmlFor="dueDate" className="form-label">Due Date*</label>
                                                            <input required type="date" className="form-control" value={formData?.dueDate} onChange={checkDate} id="dueDate" placeholder="Payment Due Date" />
                                                        </div>
                                                        <div className="col-md-12">
                                                            <label htmlFor="totalLoan" className="form-label">Total Loan</label>
                                                            <input type="number" className="form-control" min={1} value={formData?.totalLoan} onChange={handleInput} id="totalLoan" placeholder="Total Loan Amount" />
                                                        </div>

                                                        <div className="col-12">
                                                            <label htmlFor="propertyAddress" className="form-label">Property address*</label>
                                                            <input required type="text" className="form-control" value={formData?.propertyAddress} onChange={handleInput} id="propertyAddress" placeholder="1234 Main Apartment, studio, or floor" />
                                                        </div>
                                                        <div className="btn-area mt-3">
                                                            <button type='submit' className="btn cmn-btn w-100">Create Request</button>
                                                        </div>
                                                    </form>
                                                </div>

                                                {/* <h3 style={{ textAlign: 'center' ,color:"red" }} className='mt-5 pt-5' >User bank account is not linked.</h3> */}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="home-content">
                                        <div className="sales-boxes">
                                            <div className="recent-sales box">
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div className="title">Recent transfers</div>
                                                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                                                        <input
                                                            placeholder='Search by Name'
                                                            value={searchValue}
                                                            onChange={(e) => setSearchValue(e.target.value)}
                                                        />
                                                        <button
                                                            className='"btn btn-success mx-2'
                                                            style={{ padding: '10px', border: '1px solid black', borderRadius: '10px' }}
                                                            onClick={fetchSearchAccounts}
                                                        >
                                                            Search
                                                        </button>
                                                    </div>
                                                </div>
                                                <table className="table table-striped" style={{ marginTop: '25px' }}>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Borrower Name </th>
                                                            <th scope="col">Loan number </th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Requested Date</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {accountData.map((account, index) => (
                                                            <tr key={index}>
                                                                <th scope="row">{startIndex + index + 1}</th>
                                                                <td>{account?.loan?.name || "N/A"}</td>
                                                                <td>{account?.loan?.number || "N/A"}</td>
                                                                <td>{account.amount}</td>
                                                                <td>{capitalizeWord(account.status)}</td>
                                                                <td>{formatDate(account.requestedDate)}</td>

                                                                <td>
                                                                    <button className="btn btn-info" onClick={() => getTransferByID(account)}>
                                                                        View Details
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <div className="button">
                                                    {currentPage > 1 && <button className="mx-2 btn btn-info" onClick={prevPage}>Previous</button>}
                                                    {currentPage < totalPages && <button className="mx-2 btn btn-info" onClick={nextPage}>Next</button>}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div> :

                                <div className="row  justify-content-center" >
                                    <h3 className="fw-bold text-center">No {" "}
                                        <span className="fw-bold" style={{
                                            fontSize: "43px",
                                            lineHeight: "55.9px",
                                            color: 'red'
                                        }}> Account</span>   Found</h3>
                                </div>

                            }
                        </div>
                    </div>

                    <div
                        className={`modal fade ${showDialog ? "show" : ""}`}
                        tabIndex="-1"
                        style={{ display: showDialog ? "block" : "none", backgroundColor: "rgba(0,0,0,0.5)" }}
                        aria-hidden={!showDialog}
                        id="myModal1"
                        role="dialog"
                    >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Confirmation</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={handleCancel}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <p>Are you sure want to delete user?</p>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-danger" onClick={deleteAccounts}>
                                        Delete
                                    </button>
                                    <button className="btn btn-secondary" onClick={handleCancel}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default CreateTransferRequest;

