import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postApi } from "../../service/apiService";
import Header from "../../Componets/Header";

function UserRegister() {
  const navigate = useNavigate();
  const [section, setSection] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [middlename, setMiddlename] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  // const [error, setError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loader, setLoader] = useState(false);

  // Handle Signup Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Client-side form validations
    if (!firstName.trim()) {
      toast.error("First Name is required");
      return;
    }
    if (!lastName.trim()) {
      toast.error("Last Name is required");
      return;
    }
    if (!email.trim() || !/^\S+@\S+\.\S+$/.test(email)) {
      toast.error("A valid Email is required");
      return;
    }
    if (!password.trim() || password.length < 6) {
      toast.error("Password must be at least 6 characters long");
      return;
    }
    if (!phoneNumber.trim() || !/^\d{10}$/.test(phoneNumber)) {
      toast.error("A valid 10-digit Phone Number is required");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return ;
    }

    setLoader(true);
    try {
      const response = await postApi("client/signup", {
        first_name: firstName.trim(),
        middle_name: middlename.trim(),
        last_name: lastName.trim(),
        email: email.trim(),
        password: password.trim(),
        phone_number: phoneNumber.trim(),
      });

      const { token, error = null } = response.data;

      if (error) {
        if (error.errorDetails?.status === 400) {
          toast.error(error.errorDetails?.error || "Validation error occurred");
        } else {
          toast.error("An unexpected error occurred");
        }
      } else {
        toast.success("OTP has been sent to your registered email.");
        setSection(2); // Switch to OTP verification section
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoader(false);
    }
  };

  // Handle OTP Verification
  const VerifyOtp = async (e) => {
    e.preventDefault();

    if (!otp.trim()) {
      toast.error("OTP is required");
      return;
    }

    try {
      const response = await postApi("client/verify-otp", { email, otp });

      const { token, error = null } = response.data;

      if (error) {
        // setError("An error occurred while verifying OTP");
        toast.error("Invalid or expired OTP. Please try again.");
      } else {
        toast.success(
          "User registration successful. Please allow up to 24 hours for your account to be set up properly. An email will be sent to you upon completion.",
          {
            position: "top-center",
          }
        );
        setFirstName("");
        setMiddlename("");
        setLastName("");
        setEmail("");
        setPassword("");
        setPhoneNumber("");
        setConfirmPassword("");
        setOtp("");
        navigate("/user/login"); // Redirect to login page
        setSection(1); // Reset to the first section if needed
      }
    } catch (error) {
      toast.error("An error occurred while verifying OTP. Please try again.");
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    // Update phone number only if it's a number and doesn't exceed 10 digits
    if (/^\d*$/.test(value) && value.length <= 10) {
      setPhoneNumber(value);
    }
  };


  return (
    <div>
      <Header show={true} />
      {loader && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <section className="sign-in-up login">
        <div className="overlay pt-120 pb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="form-content">
                  <div className="section-header">
                    <h5 className="sub-title">
                      The Power of Financial Freedom
                    </h5>
                    <h2 className="title">Register User</h2>
                    <p>
                      Your security is our top priority. For signup, please fill
                      out the form.
                    </p>
                  </div>

                  {/* Step 1: Signup Form */}
                  {section === 1 ? (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 col-12">
                          <div className="single-input">
                            <label htmlFor="first-name">
                              Enter Your First Name
                            </label>
                            <input
                              type="text"
                              id="first-name"
                              className="form-control"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              placeholder="Your First Name here"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="single-input">
                            <label htmlFor="middle-name">
                              Enter Your Middle Name
                            </label>
                            <input
                              type="text"
                              id="middle-name"
                              className="form-control"
                              placeholder="Your Middle Name here"
                              value={middlename}
                              onChange={(e) => setMiddlename(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="single-input">
                            <label htmlFor="last-name">
                              Enter Your Last Name
                            </label>
                            <input
                              type="text"
                              id="last-name"
                              className="form-control"
                              placeholder="Your Last Name here"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 col-12">
                          <div className="single-input">
                            <label htmlFor="mobile">Enter Your Mobile</label>
                            <input
                              type="number"
                              id="mobile"
                              className="form-control"
                              placeholder="Your Mobile here"
                              value={phoneNumber}
                              onChange={handlePhoneChange}
                            />
                          </div>
                        </div>
                        <div className=" col-12">
                          <div className="single-input">
                            <label htmlFor="email">Enter Your Email</label>
                            <input
                              type="email"
                              id="email"
                              className="form-control"
                              placeholder="Your Email here"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="single-input">
                            <label htmlFor="password">
                              Enter Your Password
                            </label>
                            <input
                              type="password"
                              id="password"
                              className="form-control"
                              placeholder="Your Password here"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="single-input">
                            <label htmlFor="confirmPassword">
                            Confirm password
                            </label>
                            <input
                              type="password"
                              id="confirmPassword"
                              className="form-control"
                              placeholder="Confirm password"
                              value={confirmPassword}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                          </div>
                        </div>
                        <div
                          className="forgot-area text-end"
                          style={{ marginTop: "10px" }}
                        >
                          <Link
                            to="/user/login"
                            className="forgot-password"
                            style={{ fontSize: "13px", fontWeight: "600" }}
                          >
                            Already have an account?
                          </Link>
                        </div>
                      </div>
                      <div className="btn-area" style={{ marginTop: "20px" }}>
                        <button className="cmn-btn">Register</button>
                      </div>
                    </form>
                  ) : (
                    // Step 2: OTP Verification Form
                    <form onSubmit={VerifyOtp}>
                      <div className="row">
                        <div className="col-md-12 col-12">
                          <div className="single-input">
                            <label htmlFor="otp">Verify OTP</label>
                            <input
                              type="text"
                              id="otp"
                              className="form-control"
                              placeholder="Enter OTP"
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="btn-area" style={{ marginTop: "20px" }}>
                        <button className="cmn-btn">Submit</button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default UserRegister;
